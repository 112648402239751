import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import app from "../../firebase";
import Dropdown from "react-dropdown";
import { Label } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
export default function FormDialog({ open, setOpen, onChange, user, mode }) {
  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const classes = useStyles();
  const [rol, setRol] = React.useState(user.rol);
  const [openCombo, setComboOpen] = React.useState(false);
  const db = app.firestore();

  const handleChange = (event) => {
    const { value } = event.target;
    user.rol = value;
    setRol(value);
  };

  const handleComboClose = () => {
    setComboOpen(false);
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  const handleClose = () => {
    setOpen();
  };

  const handleAccept = async () => {
    if (mode == "update") {
      const url =
        "https://us-central1-edesarrollos-iot.cloudfunctions.net/modificar-columna";
      try {
        console.log(user);
        user.modificado = app.firestore.Timestamp.now();
        db.collection("usuarios").doc(user.id).update(user);
        handleClose();

        /*  const respo = await axios.post(url, {
          uid: user.id,
          modificar: "usuario",
          nombre: user.nombre,
          apellido: user.apellido,
          email: user.email,
          rol: rol,
        });
        console.log("res:", respo.data);
        if (respo.data.includes("Exists")) {
          alert("Este Dispositivo ya existe");
        }

        if (
          respo.data.includes(
            "Access to this account has been temporarily disabled due to many"
          )
        ) {
          alert("Contrasena invalida");
        }
        if (respo.data.includes("The email address is badly formatted.")) {
          alert("La dirección de correo electrónico está mal formateada.");
        }
        if (
          respo.data.includes("The user with the provided email already exists")
        ) {
          alert("El usuario con el correo electrónico proporcionado ya existe");
        }

        handleClose(); */
      } catch (error) {
        alert(error);
      }
    }
    if (mode == "add") {
      if (!user.nombre || !user.correo || !user.password || !user.celular) {
        alert("todos los campos son requeridos");
        return;
      }

      app
        .auth()
        .createUserWithEmailAndPassword(user.correo, user.password)
        .then(function (use) {
          dispatch({ type: USER_SIGNUP_SUCCESS }); // I dispatched some message.
          db.ref(`usuarios/${use.uid}`).set(user); // I added user
          console.log("uid:", use.uid);
        });
      handleClose();

      /*       const url =
        "https://us-central1-edesarrollos-iot.cloudfunctions.net/crear-usuario";
      try {
        const respo = await axios.post(url, user);
        console.log("res:", respo.data);
        if (respo.data.includes("Exists")) {
          alert("Este Dispositivo ya existe");
        }

        if (
          respo.data.includes(
            "Access to this account has been temporarily disabled due to many"
          )
        ) {
          alert("Contrasena invalida");
        }
        if (respo.data.includes("The email address is badly formatted.")) {
          alert("La dirección de correo electrónico está mal formateada.");
        }
        if (
          respo.data.includes("The user with the provided email already exists")
        ) {
          alert("El usuario con el correo electrónico proporcionado ya existe");
        }

        handleClose();
      }  */
      /*     catch (error) {
        alert(error);
      } */
    }
  };
  const handleSelection = (value) => {
    console.log(value);
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar Usuario</DialogTitle>
      <DialogContent>
        <Button className={classes.button} onClick={handleComboOpen}>
          Open the select
        </Button>
        {/*         <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">Rol</InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={openCombo}
            onClose={handleComboClose}
            onOpen={handleComboOpen}
            value={rol}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"administrador"}>Hola</MenuItem>
            <MenuItem value={"operador"}>Operador</MenuItem>
            <MenuItem value={"cliente"}>Cliente</MenuItem>
          </Select>
        </FormControl> */}
        <TextField
          autoFocus
          margin="dense"
          label="Nombre"
          type="text"
          fullWidth
          value={user.nombre || ""}
          onChange={(event) => onChange("nombre", event.target.value)}
        />
        {/*        <TextField
          autoFocus
          margin="dense"
          label="Apellido"
          type="text"
          fullWidth
          value={user.apellido || ""}
          onChange={(event) => onChange("apellido", event.target.value)}
        /> */}

        <TextField
          autoFocus
          margin="dense"
          id="rfc"
          label="RFC"
          type="text"
          fullWidth
          inputProps={{
            autoComplete: "new-password",
          }}
          value={user.rfc || ""}
          onChange={(event) => onChange("rfc", event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="celular"
          label="Celular"
          type="text"
          fullWidth
          inputProps={{
            autoComplete: "new-password",
          }}
          value={user.celular || ""}
          onChange={(event) => onChange("celular", event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="correo"
          label="Correo Electronico"
          type="email"
          inputProps={{
            autoComplete: "new-password",
          }}
          fullWidth
          value={user.correo || ""}
          onChange={(event) => onChange("correo", event.target.value)}
        />

        {mode === "add" && (
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Contraseña"
            type="password"
            fullWidth
            inputProps={{
              autoComplete: "new-password",
            }}
            onChange={(event) => onChange("password", event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        {mode === "add" && (
          <Button onClick={handleAccept} color="primary">
            Aggregar
          </Button>
        )}
        {mode === "update" && (
          <Button onClick={handleAccept} color="primary">
            Modificar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
