import React, {useCallback, useState} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';

const estados = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Coahuila",
  "Colima",
  "Distrito Federal",
  "Durango",
  "Estado de México",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

const HomeDialogView = ({open, title, onAccept, onCancel, onChange, model, saving}) => {
  const {imagen = ''} = model;
  const [errors, setErrors] = useState([]);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({width: 900, height: 900});

  const handleAccept = () => {

    let _errors = [];


    if (!model.activo) {
      model.activo = false;
    }

    setErrors(_errors);

    if (_errors.length) {
      console.log(errors);
      return;
    }
    onAccept();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const showCroppedImage = useCallback(async (picture) => {
    try {
      return await getCroppedImg(
        picture,
        croppedAreaPixels,
        rotation
      );

    } catch (e) {
      console.error(e);
      return null;
    }
  }, [croppedAreaPixels, rotation]);

  const handleBeforeSubmit = async () => {
    try {
      let _images = [];
      let image;


      if (model.imagen) {
        const img = await showCroppedImage(model.imagen);


        if (img.size > 200000) {
          const url = await getUrl(img);

          const resp = await fetch(url);
          const blob = await resp.blob();
          const file = new File([blob], "file", {type: "image/png"});
          const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 600,
            useWebWorker: true
          };

          try {
            const compressedFile = await imageCompression(file, options);
            image = compressedFile;
          } catch (error) {
            console.log(error);
          }
        } else {
          image = img;
        }


        setCrop({x: 0, y: 0});
        setZoom(1);
        setCroppedAreaPixels(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Agregar {title}</DialogTitle>
      <DialogContent>

        <Grid container>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('nombre')}
              variant="outlined"
              value={model.nombre}
              fullWidth
              onChange={event => onChange('nombre', event.target.value)}
              helperText={'Nombre'}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              value={model.estado}
              onChange={event => onChange('estado', event.target.value)}
              displayEmpty
              style={{width: '100%'}}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="Resto de la republica mexicana">
                <em>Resto de la republica mexicana</em>
              </MenuItem>
              {estados.map((e)=>{
                return <MenuItem key={e} value={e}>{e}</MenuItem>
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('precio')}
              variant="outlined"
              value={model.precio}
              fullWidth
              multiline
              onChange={event => onChange('precio', event.target.value)}
              helperText={'Costo de envio'}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                               name="gilad"/>}
              label="activa"
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{color: 'white', backgroundColor: '#232e5c', minWidth: "190px"}}>
          Cancelar
        </Button>
        {
          saving
            ?
            <CircularProgress style={{color: '#232e5c'}}/>
            :
            <Button onClick={handleAccept} style={{color: 'white', backgroundColor: '#232e5c'}} autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default HomeDialogView
