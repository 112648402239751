import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import DragHandleIcon from "@material-ui/icons/Reorder";
import InfoIcon from "@material-ui/icons/Info";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import Label from "@material-ui/icons/Label";
import {Link} from "react-router-dom";
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import MonochromePhotosIcon from '@material-ui/icons/MonochromePhotos';
import LocationCityIcon from "@material-ui/icons/LocationCity";
import CameraRollIcon from '@material-ui/icons/CameraRoll';
import firebase from "firebase/app";
import {useSelector} from "react-redux";
import {isEmpty, isLoaded} from "react-redux-firebase";
import Services from "./../../services";
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/Settings';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {Collapse} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "250px",
  },
  fullList: {
    width: "auto",
  },
  button: {
    height: "50%",
    cursor: "pointer",
    color: "#999999",
    fontSize: "40px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MobileDrawer = ({links}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [userAuth, setUserAuth] = useState();
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.firebase.auth);

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      const getUser = async () => {
        try {
          const user = await Services.getDocumentById("user", auth.uid);

          if (user.exists) {
            setUserAuth({...user.data(), id: user.id});
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      getUser();
    }
  }, [auth]);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const handleClick = () => {
    setOpenCollapse(!openCollapse);
  };

  const SideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List>
        {links.map((link) => {
          const Icon = link.icon;
          return (
            <Link style={{alignItems: "center"}} to="/pedidos">
              <img
                src={'https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/logokroma.png?alt=media&token=3da2d89d-845e-4b7e-92b7-9d3b19040244'}
                style={{width: "100%", height: "auto"}}/>
            </Link>
          );
        })}
      </List>
      <Divider/>
      <List>

        {userAuth && userAuth.rol === "administrador" && (
          <>
            <Link onClick={() => setOpen(false)} to="/">
              <ListItem button>
                <ListItemIcon>
                  <ViewListIcon/>
                </ListItemIcon>
                <ListItemText primary="Pedidos"/>
              </ListItem>
            </Link>
            <Link onClick={() => setOpen(false)} to="/chat">
              <ListItem button>
                <ListItemIcon>
                  <AllInboxIcon/>
                </ListItemIcon>
                <ListItemText primary="Mensajes"/>
              </ListItem>
            </Link>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <SettingsIcon/>
              </ListItemIcon>
              <ListItemText primary="Administración"/>
              {openCollapse ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <Link onClick={() => setOpen(false)} to="/productos">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <CameraRollIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Productos"/>
                </ListItem>
              </Link>
              <Link onClick={() => setOpen(false)} to="/paquetes">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <CameraRollIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Paquetes"/>
                </ListItem>
              </Link>
              <Link onClick={() => setOpen(false)} to="/clientes">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <PeopleAlt/>
                  </ListItemIcon>
                  <ListItemText primary="Clientes"/>
                </ListItem>
              </Link>
              <Link onClick={() => setOpen(false)} to="/anuncios">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <AddToHomeScreenIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Anuncios"/>
                </ListItem>
              </Link>

              <Link onClick={() => setOpen(false)} to="/ciudades">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <LocationCityIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Ciudades"/>
                </ListItem>
              </Link>
              <Link onClick={() => setOpen(false)} to="/etiquetas">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <Label/>
                  </ListItemIcon>
                  <ListItemText primary="Etiquetas"/>
                </ListItem>
              </Link>
              <Link onClick={() => setOpen(false)} to="/laboratorio">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <MonochromePhotosIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Laboratorios"/>
                </ListItem>
              </Link>
              <Link onClick={() => setOpen(false)} to="/formularios">
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <ListAltIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Formularios"/>
                </ListItem>
              </Link>
            </Collapse>
          </>
        )}

        <Link onClick={() => setOpen(false)} to="/noticePrivacy">
          <ListItem button>
            <ListItemIcon>
              <InfoIcon/>
            </ListItemIcon>
            <ListItemText primary="Aviso de privacidad"/>
          </ListItem>
        </Link>

        <Link onClick={async () => await firebase.logout()} to="/login">
          <ListItem button>
            <ListItemIcon>
              <MeetingRoomIcon/>
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión"/>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      <DragHandleIcon className={classes.button} onClick={toggleDrawer(true)}/>
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <SideList/>
      </SwipeableDrawer>
    </div>
  );
};

export default MobileDrawer;
