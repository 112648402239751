import React, {useEffect, useState} from "react";
import {Button, Container, Grid, TextField} from "@material-ui/core";
import firebase from "firebase/app";
import Page from "../../components/Page";

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async user => {
      unsubscribe();
      if (user) {
        const token = await user.getIdToken();
        resolve(token);
      }
    }, reject);
  });
}

const Token = () => {
  //const token = firebase.auth().currentUser.getIdToken();
  const [token, setToken] = useState("");
  const [uuid, setUuid] = useState();

  useEffect(() => {
    getCurrentToken().then((token) => {
      setToken(token);
    })
  }, [])

  const handleAccept = async () =>{
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const response = await (await fetch('https://app.kromalab.mx/v1/token?uid='+uuid,
      {
        method: 'GET',
        headers: headers
      }
      )).json();

    firebase.auth().signInWithCustomToken(response.resultado.token).then((usuario) => {
      window.location.reload();
    });
  }

  return (
    <Page title="Token">
      <Container maxWidth={false}>
        <Grid container spacing={3} style={{    paddingTop: '10%'}}>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              value={uuid}
              fullWidth
              label="UUID"
              onChange={event => (setUuid(event.target.value))}
            />
          </Grid>
          <Grid item xs={4}>
            <Button onClick={handleAccept} style={{color: 'white', backgroundColor: '#232e5c'}} autoFocus>
              Aceptar
            </Button>
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            {token}
          </Grid>
        </Grid>
      </Container>
    </Page>)
}

export default Token;