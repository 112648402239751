import React from "react";
import "typeface-roboto";
import { Email } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      // position: 'relative',
      fontSize: "10px",
    },
    zIndex: 999,
    position: "fixed",
    bottom: 0,
    flexGrow: 1,
    fontSize: "12px",
    backgroundColor: "black",
    // height: '50px',
    padding: "0 30px",
    color: "#ffffff",
    fontFamily: "roboto",
    "& > *": {
      // height: '100%',
      padding: 0,
      lineHeight: "50px",
      margin: "0 10px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 5px",
      },
    },
  },
  link: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "#f90c0c",
    fontSize: "14px",
    marginRight: "7px",
  },
}));

const Footer = ({ width }) => {
  const classes = useStyles();

  return (
    <Grid container item className={classes.root} xs={12} justify="center">
      <p>
        Derechos reservados <b>KromaLab</b> 2021 &#xa9;
      </p>
      <p>|</p>
      <Link to="/" className={classes.link}>
        <Email className={classes.icon} style={{color: "#f90c0c"}}/>
        {["xs"].includes(width) ? null : "Contacto"}
      </Link>
    </Grid>
  );
};

export default withWidth()(Footer);
