import React, {useState} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Crop from '../../components/Crop/Crop';
import AddIcon from "@material-ui/icons/add";
import EditIcon from "@material-ui/icons/Edit";
import MultiCrop from "../../components/Crop/MultiCrop";


const HomeDialogView = ({
                          open,
                          title,
                          onAccept,
                          onCancel,
                          onChange,
                          onDelete,
                          onChangePaquete,
                          model = {carousel: []},
                          saving }) => {

  const {imagen = ''} = model;
  const [errors, setErrors] = useState([]);


  const handleAccept = () => {

    let _errors = [];

    if (!model.activo) {
      model.activo = false;
    }

    setErrors(_errors);

    if (_errors.length) {
      console.log(errors);
      return;
    }
    onAccept();
  };


  return (
    <Dialog open={open}>
      <DialogTitle>Agregar {title}</DialogTitle>
      <DialogContent>

        <Grid container>
          <Grid item xs={10}>
            <TextField
              error={errors.includes('nombre')}
              variant="outlined"
              value={model.nombre}
              fullWidth
              onChange={event => onChange('nombre', event.target.value)}
              helperText={'Nombre'}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                               name="gilad"/>}
              label="activa"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors.includes('descripcion')}
              variant="outlined"
              value={model.descripcion}
              fullWidth
              multiline
              onChange={event => onChange('descripcion', event.target.value)}
              helperText={'Descripción'}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={3} xs={3}>
            <Button style={{backgroundColor: '#36bea1', color: 'white'}} component="label">
              EDITAR <EditIcon/>
              <input type="file" accept="image/x-png,image/gif,image/jpeg"
                     style={{display: "none"}}
                     onChange={event => event.target.files.length && onChange('imagen', event.target.files[0])}
              />
            </Button>
          </Grid>

          <Grid item md={9} xs={9}>

            <Crop
              url={imagen instanceof File ? URL.createObjectURL(imagen) : imagen}
              onChange={(_imageFile) => {
                if (_imageFile) {
                  onChange('imagenCrop', _imageFile);
                }
              }}
            />

          </Grid>
        </Grid>

        {/*imagenes secundarias*/}
        <Grid container>
          <Grid item md={12} xs={12}>
            <Button style={{backgroundColor: '#aca515', color: 'white', width: "100%", margin: "3% 0"}}
                    component="label">
              Agregar imagen secundaria <AddIcon/>
              <input type="file" accept="image/x-png,image/gif,image/jpeg"
                     style={{display: "none"}}
                     onChange={event => {
                       if (event.target.files[0]) {
                         onChange('carouselCrop', event.target.files[0], 0, false)
                       }

                     }}
              />
            </Button>
          </Grid>
          <MultiCrop
            onChange={(imageCropFile, index) => {
              onChange('carouselCrop', imageCropFile, index, true)
            }}
            imageParams={model.carousel}
            onDelete={(image, index) => onDelete(image, index)}
          />


        </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{color: 'white', backgroundColor: '#232e5c'}}>
          Cancelar
        </Button>
        {
          saving
            ?
            <CircularProgress style={{color: '#232e5c'}}/>
            :
            <Button onClick={handleAccept} style={{color: 'white', backgroundColor: '#232e5c'}} autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default HomeDialogView
