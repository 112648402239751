import React, {useEffect, useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import {isEmpty, isLoaded} from "react-redux-firebase";
import Services from '../../services';
import SendIcon from '@material-ui/icons/Send';
import firebase from '../../firebase';
import {useSelector} from "react-redux";
import moment from "moment";
import 'moment/locale/es'

const useStyles = makeStyles((theme) => ({
  root: {},
  globoRecibido: {
    background: "white",
    padding: "1%",
    margin: "3%",
    borderRadius: "10px",
  },
  globoEnviado: {
    background: "white",
    padding: "1%",
    margin: "3%",
    borderRadius: "10px",
    marginLeft: "30%",
  },
  textoCliente: {
    fontSize: "10px",
    textAlign: "right",
    color: "#c0c0c0"
  },
  chatBox: {
    backgroundColor: "#d1d1d1",
    height: "360px",
    margin: "2%",
    padding: "2%",
    overflow: "auto",
  }
}));

const MessageDialogView = ({open, title, onAccept, onCancel, onChange, model, mensajes, saving, tipo}) => {
  const auth = useSelector(state => state.firebase.auth);
  const [errors, setErrors] = useState([]);
  const [nuevoMensaje, setNuevoMensaje] = useState("");
  const [mensajesLoc, setMensajesLoc] = useState({});
  const [usuario, setUsuario] = useState(null);
  const classes = useStyles();


  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      const getUser = async () => {
        try {
          const user = await Services.getDocumentById("user", auth.uid);

          if (user.exists) {
            setUsuario({...user.data(), id: user.id});
          }
        } catch (error) {
          console.log(error);
        }
      };
      getUser();
    }
  }, [model, tipo]);


  useEffect(() => {
    setMensajesLoc(mensajes)
  }, [mensajes, open])

  useEffect(() => {
    chats(mensajesLoc)
  }, [mensajesLoc])

  const handleAccept = () => {
    let _errors = [];

    if (!model.activo) {
      model.activo = false;
    }

    setErrors(_errors);

    if (_errors.length) {
      console.log(errors);
      return;
    }
    onAccept();
  };


  const handleBeforeSubmit = async () => {
    try {
      let _images = [];
      let image;
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeEvent = (value) => {
    setNuevoMensaje(value);
  }

  const responderChat = () => {
    let data = {};
    let _mensajes = mensajesLoc ? mensajesLoc : mensajes;

    if (nuevoMensaje === '')
      return;

    data = {
      fecha: new Date(),
      idSala: model.id,
      idUsuario: auth.uid,
      mensaje: nuevoMensaje,
      nombreUsuario: usuario.nombre,
      tipo: 0,
      visto: 1
    }

    setNuevoMensaje("");
    Services.addDocument("mensajes", data);
    _mensajes.push(data)
    setMensajesLoc(_mensajes);
  }

  const dateRow = (row, type = "") => {
    try {
      if (type === "string") {
        row = row.split('T');
        let arrDate = row[0].split('-');
        let arrHora = row[1].split('.');

        return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0] + " " + arrHora[0]
      } else {
        return moment(Date.parse(row.toDate())).locale('es-mx').format('LLLL')
      }
    } catch (error) {
      console.log(error)
    }

  }

  const marcarVisto = mensaje => {

    let data = {
      visto: 1,
      fechaVisto: new Date()
    }
    let _mensaje = Services.updateDocument("mensajes", mensaje, data);
  }

  const chats = (rows) => {
    let _mensajes = [];
    if (rows !== undefined && rows.length > 0) {
      rows.sort((a, b) => a.fecha < b.fecha ? -1 : 1);
      rows.map((mensaje) => {
        _mensajes.push(
          <Grid key={mensaje.id} item xs={8}
                className={mensaje.idUsuario === auth.uid ? classes.globoEnviado : classes.globoRecibido}>
            <Typography>{mensaje.mensaje}</Typography>
            <Typography
              className={classes.textoCliente}>{mensaje.nombreUsuario} - {dateRow(mensaje.fecha)}</Typography>
          </Grid>);
        if (mensaje.visto === undefined || mensaje.visto === 0) {
          if(mensaje.idUsuario !== auth.uid ){
            marcarVisto(mensaje.id);
          }
        }
      })

    } else {
      _mensajes.push(
        <Grid item xs={8}>
          <Typography>no se encontraron mensajes</Typography>
        </Grid>)
    }
    return _mensajes;
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.chatBox}>

        <Grid container>
          <Grid item xs={12}>
            {chats(mensajesLoc)}
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>

        <Grid container>
          <Grid item xs={10}>
            <TextField
              error={errors.includes('Nuevo Mensaje')}
              variant="outlined"
              value={nuevoMensaje}
              fullWidth
              onChange={event => onChangeEvent(event.target.value)}
              helperText={''}/>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={responderChat}
              variant="outlined"
              fullWidth
              size='large'
              style={{color: 'white', backgroundColor: '#154901', height: "98%"}}>
              <SendIcon/>
            </Button>
          </Grid>
          <Grid item xs={12} style={{textAlign: "right", marginTop: '10px'}}>
            <Button
              onClick={onCancel}
              style={{color: 'white', backgroundColor: '#232e5c', minWidth: "190px"}}>
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default MessageDialogView
