import React, {useEffect} from "react";
import {useFirestoreConnect,} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Box, Button, Container, makeStyles, Typography} from "@material-ui/core";
import Page from "../../components/Page";
import Dialog from './Dialog'
import Results from "./Results";
import Services from '../../services';
import FormsDialog from "./FormsDialogView";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const title = "Formularios";

const header = [
  {
    prop: "formName",
    name: "Nombre formulario",
  },
  {
    prop: "formButtom",
    name: "mensaje de boton",
  },
  {
    prop: "formMessaje",
    name: "mensaje al finalizar llenado",
  },
];


const Dashboard = () => {

  useFirestoreConnect([
    {
      collection: "formulario"
    },
  ]);

  const [openCombo, setComboOpen] = React.useState(false);
  const [models, setModels] = React.useState([]);
  const [model, setModel] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleComboClose = () => {
    setComboOpen(false);
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  const modelsSelector = useSelector(
    (state) => state.firestore.ordered.formulario
  );

  const classes = useStyles();

  useEffect(() => {
    try {
      if (modelsSelector) {
        let _models = [...modelsSelector];

        setModels(_models);
      }
    } catch (e) {
      console.log(e);
    }
  }, [modelsSelector]);

  const add = async () => {
    try {

      let data = {
        formButtom: model.formButtom === undefined ? "" : model.formButtom,
        formMessaje: model.formMessaje === undefined ? "" : model.formMessaje,
        formName: model.formName === undefined ? "" : model.formName,
        fecha: new Date(),
        form: model.form,
        activo: model.activo,
      }

      if (model.imagen) {
        const url = await Services.uploadFile('formulario/' + model.imagen.name, model.imagen);
        data.imagen = url;
        data.imagenPath = 'formulario/' + model.imagen.name;
      }

      await Services.addDocument('formulario', data);

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
      setModel([]);
    }
  };

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Box display="flex" p={1} mt={2}>
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
              variant="contained"
            >
              Nuevo
            </Button>
          </Box>


        </Box>
        <Box mt={3}>
          <Results data={models} title={title} header={header}/>
        </Box>
        <Box height="60"></Box>


      </Container>
      <Dialog
        open={open}
        onAccept={add}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {

          if (key === 'image') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          } else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          } else {
            setModel({...model, [key]: value});
          }
        }}
        model={model}
        title={title}
      />
    </Page>
  );
};

export default Dashboard;
