import asyncComponent from "../components/AsyncComponent";
import NoticePrivacy from "./noticePrivacy/NoticePrivacyView";
import AdminView from "./UserAdmin/userView";

import ShowFormView from "./Forms/FormsShowView";

import WebView from "./web/WebView";
const LogInView = asyncComponent(() => import("./LogInView"));
const HomeView = asyncComponent(() => import("./HomeView"));

export { HomeView, LogInView, ShowFormView, NoticePrivacy, WebView, AdminView };
