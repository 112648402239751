import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import {Box, Card, Chip, IconButton, makeStyles, Typography,} from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import {Link} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './Dialog'
import Services from '../../services';
import DeleteDialog from '../../components/DeleteDialog';

const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },
  {
    prop: "descripcion",
    name: "Descripción",
  },
  {
    prop: "minimo",
    name: "Fotos Minimas",
  },
  {
    prop: "maximo",
    name: "Fotos Maximas",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({className, handleSync, handleEdit, handleDelete, title, data, ...rest}) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);

  const update = async () => {
    try {

      let data = {
        nombre: model.nombre === undefined ? "" : model.nombre,
        activo: model.activo,
        descripcion: model.descripcion === undefined ? "" : model.descripcion,
        minimo: model.minimo === undefined ? "" : model.minimo,
        maximo: model.maximo === undefined ? "" : model.maximo,
        idProducto: model.idProducto === undefined ? "" : model.idProducto,
        precio: model.precio === undefined ? "" : model.precio,
        tamano: model.x + "x" + model.y,
        x: model.x === undefined ? 0 : parseInt(model.x),
        y: model.y === undefined ? 0 : parseInt(model.y),
        fecha: new Date(),
      };

      if (model.imagen && typeof model.imagen !== "string") {
        const url = await Services.uploadFile('paquete/' + model.imagen.name, model.imagen);
        data.imagen = url;
        data.imagenPath = 'paquete/' + model.imagen.name;
      }

      await Services.updateDocument('paquete', model.id, data)

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
      setModel([]);
    }
  };

  const borrar = async () => {
    try {
      await Services.delete('paquete', model.id);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogDelete(false);
      setModel([]);
    }
  };

  const rowActivo = async (model, type) => {
    let data = {
      activo: type
    };
    await Services.updateDocument('paquete', model.id, data)
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "descriocion", "uid"]} extraRows={[
        {

          prop: "tamano",
          name: "Tamaño",
          cell: row => (
            <Typography>{row.tamano === '0x0' ?  "No aplica" : row.tamano}</Typography>
          )
        },
        {

          prop: "precio",
          name: "Precio",
          cell: row => (
            <Typography>$ {row.precio}</Typography>
          )
        },
        {
          prop: "estatus",
          name: "Estatus",
          cell: (row) => (
            <Link
              to={{
                pathname: `/paquetes`,
                state: row,
              }}
            >
              <Box>
                {row.activo == true ? (
                  <Chip
                    icon={<NewReleases style={{color: "white"}}/>}
                    size="small"
                    label="Activo"
                    clickable
                    onClick={() => {
                      rowActivo(row, false)
                    }}
                    style={{color: "white", backgroundColor: "green"}}
                  />
                ) : <Chip
                  icon={<NewReleases style={{color: "white"}}/>}
                  size="small"
                  label="Inactivo"
                  clickable
                  onClick={() => {
                    rowActivo(row, true)
                  }}
                  style={{color: "white", backgroundColor: "red"}}
                />}

              </Box>
            </Link>
          ),
        },
        {
          prop: 'actions',
          name: '',
          cell: row => (
            <>
              <IconButton
                style={{backgroundColor: "#f0ed18", color: "white"}}
                onClick={() => {
                  setOpen(true);
                  setModel(row);
                }}>
                <EditIcon/>
              </IconButton>
              <IconButton
                style={{backgroundColor: "#f02d1d", color: "white"}}
                onClick={() => {
                  setOpenDialogDelete(true);
                  setModel(row);
                }}>
                <DeleteIcon/>
              </IconButton>
            </>
          )
        }
      ]}
      />
      <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === 'image') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          } else if (key === 'maximo' || key === 'minimo') {
            setModel({...model, [key]: value.replace('.', '')});
          } else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          } else {
            setModel({...model, [key]: value});
          }

        }}
        model={model}
        title={title}
      />
      <DeleteDialog
        open={openDialogDelete}
        onAcceptDelete={borrar}
        onCancel={() => {
          setOpenDialogDelete(false);
          setModel({});
        }}
      />
    </Card>


  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
