import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogContent, Grid, Typography} from '@material-ui/core';
import {useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from "react-redux";
import Container from "../../components/Container";
import Table from "../../components/Table";


const HomeDialogView = ({open, title, onAccept, onCancel, onChange, model, saving}) => {

  const {imagen = ''} = model;
  useFirestoreConnect([
    {
      collection: "cuestionarios",
      where: [["formId", "==", model.id ? model.id : ""]],
      orderBy: ["DateCreate", "desc"]
    }
  ]);

  const modelsSelector = useSelector(
    (state) => state.firestore.ordered.cuestionarios
  );

  const handleCancel = () => {
    onCancel();
  }

  const [errors, setErrors] = useState([]);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [cuestionarios, setCuestionarios] = useState([])
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({width: 900, height: 900});
  const [header, setHeader] = useState([]);


  /*{
    prop: "tipoProducto",
      name: "Producto",
    cell: (row) => (
    console.log(row)
  ),
  },*/
  useEffect(() => {
    try {
      if (model.form) {
        let i=0;
        setHeader(model.form.map((item) => {
          let result = {prop: i, name: item.title};
          i++;
          return result
        }))
      }

    } catch (e) {
      console.log(e);
    }
  }, [model]);

  useEffect(() => {
    try {
      if (modelsSelector) {
        let _cuestionarios = [...modelsSelector.map((cuestionario) => {
          return cuestionario.quiz
        })];

        _cuestionarios = _cuestionarios.map( item => {
          return item.map(i =>{
            return i.answer ;
          })
        })

        console.log(_cuestionarios);

        header.map(itemHeader=>{
          return itemHeader;
        })

        setCuestionarios(_cuestionarios);
      }

    } catch (e) {
      console.log(e);
    }
  }, [modelsSelector]);


  return (
    <Dialog open={open}>
      <DialogContent>
        <Container>
          <Grid item={12}>
            <Typography>{model.formName}</Typography>
          </Grid>
          <Grid item={12}>
            <Table
              header={header}
              data={cuestionarios}
              paginated
              filter={["uid", 0, 1, 2]}
            />
          </Grid>
        </Container>
        <Button onClick={handleCancel} style={{color: '#4caf50', backgroundColor: 'white'}} color="primary">
          Salir
        </Button>
      </DialogContent>
    </Dialog>
  )
}


export default HomeDialogView
