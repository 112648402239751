import React, {useEffect, useState} from 'react';
import {Button, Grid} from "@material-ui/core";
import Crop from "./Crop";
import DeleteIcon from "@material-ui/icons/Delete";

const MultiCrop = ({onChange, imageParams = [], cropProp = null, onDelete}) => {
  const [images, setImages] = useState([]);
  const [crop, setCrop] = useState({unit: "%", width: 100, aspect: 16 / 9});

  useEffect(() => {
    if (cropProp) setCrop(cropProp);
  }, [cropProp]);

  useEffect(() => {
    if (imageParams) {
      setImages(imageParams);
    }
  }, [imageParams]);

  return (
    <>
      {
        images.map((image, index) => (
          <Grid container
                key={index}
                style={{margin:"3% 0"}}
          >
            <Grid
              item
              md={8} xs={8}
              style={{maxHeight: "300px", minHeight: "300px", textAlign: "center"}}
            >
              <Crop
                url={image.imagen instanceof File ? URL.createObjectURL(image.imagen) : image.imagen}
                onChange={(newFile) => {
                  onChange(newFile, index)
                }}
                cropProp={crop}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <Button
                style={{backgroundColor: '#dd0707', color: 'white', width: "100%", margin: "3% 0"}}
                component="label"
                onClick={() => {
                  onDelete(image, index)
                  setCrop({unit: "%", width: 100, aspect: 16 / 9})
                }}>
                Eliminar <DeleteIcon/>
              </Button>
            </Grid>
          </Grid>
        ))
      }
    </>
  )
}

export default MultiCrop;