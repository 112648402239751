import React, {useEffect, useState} from 'react';
import {isLoaded, useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';

import Loader from './../../components/Loader';
import ShowText from './../forms/typeOptios/ShowText';
import ShowImage from './../forms/typeOptios/ShowImage';
import ShowMultimedia from './../forms/typeOptios/ShowMultimedia';
import ShowMultipleQuestion from './../forms/typeOptios/ShowMultipleQuestion';
import ShowTextInput from './../forms/typeOptios/ShowTextInput';
import ShowTwoTextInput from './../forms/typeOptios/ShowTwoTextInput';
import ShowTextTwoHeader from './../forms/typeOptios/ShowTextTwoHeader';
import ShowTwoOptionsQuestions from './../forms/typeOptios/ShowTwoOptionsQuestions';
import {Button, Card, Container, Grid, Typography} from '@material-ui/core';
import Services from './../../services/index';
import firebase from "firebase";

export default function ShowFormView({match}) {
  useFirestoreConnect([
    {
      collection: 'formulario',
      doc: match.params.id,
    }
  ]);

  const auth = useSelector(state => state.firebase.auth);
  const [quiz, setQuiz] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [form, setForm] = useState([]);

  const modelsSelector = useSelector(
    (state) => state.firestore.ordered.formulario
  );

  useEffect(() => {
    try {
      if (modelsSelector) {
        let _form = [...modelsSelector];

        console.log(_form)
        setForm(_form);
      }
    } catch (e) {
      console.log(e);
    }
  }, [modelsSelector]);

  if (!isLoaded(modelsSelector)) {
    return <Loader/>;
  }

  const onChangeAnswer = (index, type, value, answerIndex) => {
    const formCopy = quiz ? [...quiz] : [...form[0].form];

    if (type == 'multipleQuestion' || type == 'twoTextInput') {
      const answerCopy = quiz ? [...quiz[index].answer] : [...formCopy[index].answer];
      answerCopy[answerIndex] = value;
      formCopy[index] = {...formCopy[index], answer: answerCopy};
    } else {
      formCopy[index] = {...formCopy[index], answer: value};
    }
    setQuiz(formCopy);
  }


  const getTypeOptions = (item, index) => {
    switch (item.type) {
      case 'showText':
        return <ShowText text={item.title ? item.title : 'Sin especificar'}/>
      case 'showTextTwoHeader':
        return <ShowTextTwoHeader text={item.title ? item.title : 'Sin especificar'}/>
      case 'showImage':
        return <ShowImage imageUrl={item.imageUrl
          ? item.imageUrl
          : 'https://firebasestorage.googleapis.com/v0/b/place-valid.appspot.com/o/descarga.png?alt=media&token=be26e19e-249c-43f6-be36-873a6764f737'}
        />
      case 'showMultimedia':
        return <ShowMultimedia
          audioUrl={item.audioUrl ? item.audioUrl : ''}
          videoUrl={item.videoUrl ? item.videoUrl : ''}
        />
      case 'multipleQuestion':
        return <ShowMultipleQuestion
          title={item.title ? item.title : 'Sin especificar'}
          options={item.options}
          answer={quiz ? quiz[index].answer : item.answer}
          onChange={(answer, answerIndex) => onChangeAnswer(index, item.type, answer, answerIndex)}
        />
      case 'twoOptionQuestion':
        return <ShowTwoOptionsQuestions
          title={item.title ? item.title : 'Sin especificar'}
          options={item.options}
          answer={quiz ? quiz[index].answer : 0}
          onChange={(answer) => onChangeAnswer(index, item.type, answer)}
        />
      case 'textInput':
        return <ShowTextInput
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
      case 'twoTextInput':
        return <ShowTwoTextInput
          //title={item.title ? item.title : 'Sin especificar'}
          answer={(answerIndex, answer) => onChangeAnswer(index, item.type, answer, answerIndex)}
          multiline={false}
        />
      case 'number':
        return <ShowTextInput
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
      case 'email':
        return <ShowTextInput
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
      case 'phone':
        return <ShowTextInput
          title={item.title ? item.title : 'Sin especificar'}
          answer={(answer) => onChangeAnswer(index, item.type, answer)}
          multiline={false}
        />
    }
  }
  const save = () => {
    if (quiz !== null) {
      for (let i in quiz) {
        let campo = quiz[i]
        if (campo.type === "twoTextInput" || campo.type === "showTextTwoHeader") {
          console.log(campo)
        } else if (campo.answer === null || campo.answer === "") {
          alert("El campo " + campo.title + " no puede estar vacio");
          return;
        }
      }
    } else {
      alert("Debe de llenar el formulario");
      return;
    }
    let f = new Date();
    let date = f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear() + " " + f.getHours() + " : " + f.getMinutes();

    Services.addDocument('cuestionarios', {
      quiz: quiz,
      formId: match.params.id,
      DateCreate: firebase.firestore.Timestamp.fromDate(new Date())
    })
      .then(() => setAnswered(true))
      .catch((error) => console.log(error));
  }

  console.log(form);
  if(form.length > 0)
  return (
    <>
      {
        answered
          ?
          (form[0].formRedirect === undefined || form[0].formRedirect === null || form[0].formRedirect === "" ?
              <Typography align="center" style={{marginTop: '15%', fontSize: 30}}>
                Gracias!, Nos pondremos en contacto
              </Typography> :
              window.location.href = form[0].formRedirect
          )
          :
          <Container style={{marginTop: 20, marginBottom: 80}}>
            <Grid container>
              <Grid item={12}>
                <Typography align="center" style={{marginTop: '15%', fontSize: 30}}> {form[0].formTitle} </Typography>
              </Grid>
            </Grid>
            {
              form[0].form.map((item, index) => (
                <Card key={index} style={{marginTop: 10, padding: 10, width: '100%'}}>
                  {getTypeOptions(item, index)}
                </Card>
              ))
            }
            <Grid container direction='row' justify='flex-end'>
              <Button
                color="primary"
                style={{color: 'white', marginTop: 20, backgroundColor: '4caf50'}}
                onClick={() => save()}
              >
                {form[0].formButtom === undefined || form[0].formButtom === null || form[0].formButtom === "" ? "Aceptar" : form[0].formButtom}
              </Button>
            </Grid>
          </Container>
      }
    </>
  );
  else
    return  <Loader/>

}