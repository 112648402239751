import {
  Card,
  Typography,
  CardContent,
  Box,
  Container,
  Grid,
  makeStyles,
  responsiveFontSizes,
} from "@material-ui/core";

import Page from "../../components/Page";
import Status from "./Status";
import LatestOrders from "./LatestOrders";
import LatestProducts from "./LatestProducts";
import Sales from "./Sales";
import TasksProgress from "./TasksProgress";
import TotalCustomers from "./TotalCustomers";
import TotalProfit from "./TotalProfit";
import TrafficByDevice from "./TrafficByDevice";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import AcceptDialog from "./AcceptDialog";
import app from "../../firebase";
import DriverTable from "./Drivers";
import React, { useEffect, useState } from "react";
import Results from "./Results";
import MapChart from "./MapChart";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { DriveEtaRounded } from "@material-ui/icons";
import { merge, set } from "lodash";
import DeleteDialog from "../../components/DeleteDialog";
import DeleteChofer from "./Delete";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
const mapStyles = {
  height: "100%",
  width: "100%",
};
const OrderView = ({ location }) => {
  useFirestoreConnect([
    {
      collection: "usuarios",
      where: [["rol", "==", "chofer"]],
      /* orderBy: ['name'] */
    },
    {
      collection: "pedidos",
      orderBy: ["fecha", "desc"],
    },
    {
      collection: "pedidos",
      doc: location.state.id,
    },
  ]);

  const [initialCenter, setinitialCenter] = React.useState({});
  const [chofer, setChofer] = React.useState({});
  const [pedidoref, setPedidoref] = React.useState({});

  const [estatus, setEstatus] = React.useState("");
  const [currentPosition, setcurrentPosition] = React.useState({});
  const [currentPosition2, setcurrentPosition2] = React.useState({});
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDeleteChofer, setOpenDeleteChofer] = React.useState(false);

  const [openAccept, setOpenAccept] = React.useState(false);

  const [openCombo, setComboOpen] = React.useState(false);
  const db = app.firestore();
  const [usuarios, setUsuarios] = React.useState([]);
  const [address, setAddress] = React.useState("");

  const classes = useStyles();
  console.log(location.state);
  const pedido = useSelector(
    ({ firestore: { data } }) => data.pedidos && data.pedidos[location.state.id]
  );

  const pedidosSelector = useSelector(
    (state) => state.firestore.ordered.pedidos
  );

  const usuariosSelector = useSelector(
    (state) => state.firestore.ordered.usuarios
  );

  useEffect(() => {
    try {
      if (usuariosSelector && usuariosSelector.length) {
        setUsuarios(usuariosSelector);
      }
    } catch (e) {
      console.log(e);
    }
  }, [usuariosSelector]);

  useEffect(() => {
    if (currentPosition.lat) {
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            currentPosition.lat +
            "," +
            currentPosition.lng +
            " &location_type=ROOFTOP&result_type=street_address&key=AIzaSyDqYt-tqtsE67cD7kYrktcs35XlTLXuxGI",
          {
            "Content-type": "application/json",
            Accept: "application/json",
          }
        )
        .then((response) => {
          console.log(response);
          setAddress(response.data.results[0].formatted_address);
        });
    } else if (currentPosition2) {
    }
  }, [currentPosition, currentPosition2]);

  useEffect(() => {
    try {
      const t = location.state.id;
      console.log(t);
      db.collection("pedidos")
        .doc(t)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.exists) {
            const data = querySnapshot.data();

            setinitialCenter({
              lat: data.direccion.latitud,
              lng: data.direccion.longitud,
            });
            setcurrentPosition({
              lat: data.direccion.latitud,
              lng: data.direccion.longitud,
            });
            if (data.estatus == "EN RUTA" || data.estatus == "TERMINADO") {
              /*     setcurrentPosition2({
                lat: data.chofer.latitud,
                lng: data.chofer.longitud,
              }); */
              setcurrentPosition2({
                lat: 29.091072988133902,
                lng: -111.0068488091316,
              });
            }

            console.log(currentPosition2);

            console.log("text", data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }, [pedidosSelector]);

  const handleComboOpen = () => {
    setComboOpen(true);
  };
  const handleComboClose = () => {
    setComboOpen(false);
  };
  const handleSetDriver = async (row) => {
    try {
      const t = location.state.id;
      // console.log(t);
      console.log(pedido);

      await db.collection("usuarios").doc(row.id).update({ estatus: 1 });
      await db
        .collection("pedidos")
        .doc(t)
        .update({ estatus: "ASIGNADO", chofer: row });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetTerminado = async () => {
    try {
      const t = location.state.id;
      // console.log(t);
      console.log(pedido);
      await db
        .collection("usuarios")
        .doc(pedido.chofer.id)
        .update({ estatus: 0 });
      await db.collection("pedidos").doc(t).update({ estatus: "TERMINADO" });
    } catch (e) {
      console.log(e);
    }
    setOpenAccept(false);
  };
  const handleGoBack = async () => {
    try {
      const t = location.state.id;
      // console.log(t);
      console.log(pedido);
      if (pedido.estatus == "ASIGNADO") {
        await db
          .collection("usuarios")
          .doc(pedido.chofer.id)
          .update({ estatus: 0 });
        await db.collection("pedidos").doc(t).update({ estatus: "NUEVO" });
      }
      if (pedido.estatus == "EN RUTA") {
        await db
          .collection("usuarios")
          .doc(pedido.chofer.id)
          .update({ estatus: 0 });
        await db.collection("pedidos").doc(t).update({ estatus: "ASIGNADO" });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const removeDriver = async () => {
    try {
      const t = location.state.id;

      await db
        .collection("pedidos")
        .doc(t)
        .update({ estatus: "NUEVO", chofer: {} });
      await db.collection("usuarios").doc(chofer.id).update({ estatus: 0 });
    } catch (e) {
      console.log(e);
    }
    setOpenDeleteChofer(false);
  };

  const removePedido = async () => {
    try {
      const t = location.state.id;

      await db
        .collection("pedidos")
        .doc(t)
        .update({ estatus: "CANCELADO", chofer: {} });
      await db
        .collection("usuarios")
        .doc(pedido.chofer.id)
        .update({ estatus: 0 });
    } catch (e) {
      console.log(e);
    }
    setOpenDelete(false);
  };

  const handleSetRuta = () => {
    try {
      setcurrentPosition2({
        lat: 29.091072988133902,
        lng: -111.0068488091316,
      });

      const t = location.state.id;

      db.collection("pedidos").doc(t).update({
        estatus: "EN RUTA",
      });
    } catch (e) {
      console.log(e);
    }
  };
  const deletePedido = async () => {
    console.log(pedidoref.id);
    db.collection("pedidos").doc(pedidoref.id).update({ estatus: "CANCELADO" });
    const t = location.state.id;
    db.collection("usuarios").doc(pedidoref.chofer.id).update({ estatus: 0 });
    setOpenDelete(false);
  };
  if (!isLoaded(pedido)) return null;

  return (
    <Page className={classes.root} title="Dashboard">
      <DeleteDialog
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onClose={() => setOpenDelete(false)}
        onAcceptDelete={removePedido}
      />
      <DeleteChofer
        open={openDeleteChofer}
        onCancel={() => setOpenDeleteChofer(false)}
        onClose={() => setOpenDeleteChofer(false)}
        onAccept={removeDriver}
      />
      <AcceptDialog
        open={openAccept}
        onCancel={() => setOpenAccept(false)}
        onClose={() => setOpenAccept(false)}
        onAccept={handleSetTerminado}
      />
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Status info={pedido} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress
              info={pedido}
              handleRuta={() => {
                // console.log(pedido.id);
                handleSetRuta();
              }}
              handleTerminado={() => {
                // console.log(pedido.id);
                setOpenAccept(true);
              }}
              handleBack={() => {
                // console.log(pedido.id);
                handleGoBack();
              }}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers info={pedido} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit info={pedido} />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              ESCOGE UN CONDUCTOR
            </Typography>
            <DriverTable
              data2={pedido}
              data={usuarios}
              handledriver={(row) => {
                // console.log(pedido.id);
                handleSetDriver(row);
              }}
              handleRemoveDriver={(row) => {
                // console.log(pedido.id);
                setChofer(row);
                setOpenDeleteChofer(true);
              }}
            />
            <Box height="40px"></Box>
            <Typography color="textSecondary" gutterBottom variant="h6">
              DATOS DEL PEDIDO
            </Typography>
            <Results
              data={[pedido]}
              handleRemovePedido={(row) => {
                setPedidoref(row);
                setOpenDelete(true);
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <Card>
              <Typography>{address}</Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default OrderView;
