import React from "react";
import "typeface-roboto";
import {Box, Button, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Home, MeetingRoom} from "@material-ui/icons";
import {Link, NavLink} from "react-router-dom";
import UserDetail from "./UserDetail";
import {withRouter} from "react-router";
import MobileHeader from "./MobileDrawer";
import firebase from "firebase/app";

const links = [{to: "/", text: "Inicio", icon: Home}];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textTransform: "uppercase",
    backgroundColor: "#fafafa",
    position: "relative",
    zIndex: "999",
    boxShadow: "-1px -3px 18px 6px rgba(0,0,0,0.12)",
    height: "80px",
    "& > *": {
      height: "100%",
    },
    "& img": {
      width: "120px",
    },
  },
  userOptions: {
    height: "90%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileButton: {
    display: "flex",
  },
  link: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    textDecoration: "none",
    fontFamily: "roboto",
    color: "#575555",
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.07)",
    },
  },
  active: {
    backgroundColor: "rgba(0,0,0,0.07)",
  },
  icon: {
    color: "#575555",
  },
  imagenLogotipo: {
    width: '250px !important'
  },
}));

const getNavLinks = (links, classes) => {
  return links.map((link) => {
    return (
      <NavLink
        exact={Boolean(link.to === "/")}
        key={link.to}
        to={link.to}
        activeClassName={classes.active}
        className={classes.link}
      >
        {link.text}
      </NavLink>
    );
  });
};

const Header = ({location, hideHeaderLinks}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} /* px={4}  */>
      <Grid container>
        <Grid
          xs={1}
          className={classes.mobileButton}
          item
          container
          justify="center"
        >
          <MobileHeader links={links}/>
        </Grid>
        {/*   <Grid item xs={2}>

        </Grid> */}
        <Grid
          xs={9}
          item
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          style={{height:'85px'}}
        >
          <Link to="/">
            <img className={classes.imagenLogotipo} src={'https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/logokroma.png?alt=media&token=3da2d89d-845e-4b7e-92b7-9d3b19040244'}/>
          </Link>
          {hideHeaderLinks ? null : getNavLinks(links, classes)}
        </Grid>
        <Grid xs={2} className={classes.userOptions} item>
          <Grid
            container
            style={{height: "100%"}}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >

            <UserDetail
              active={
                (location && location.pathname.includes("profile")) ||
                location.pathname.includes("administracion")
              }
            />
            <Button>
              <Link to="/login" onClick={async () => await firebase.logout()}>
                <MeetingRoom className={classes.icon}/>
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(Header);
