import React, {useCallback, useState} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Crop from '../../components/Crop/Crop';



const HomeDialogView = ({open, title, onAccept, onCancel, onChange, model, saving}) => {
  const {imagen = ''} = model;
  const [errors, setErrors] = useState([]);

  const handleAccept = () => {

    let _errors = [];

    if (!model.activo) {
      model.activo = false;
    }

    setErrors(_errors);

    if (_errors.length) {
      console.log(errors);
      return;
    }
    onAccept();
  };


  return (
    <Dialog open={open}>
      <DialogTitle>Agregar {title}</DialogTitle>
      <DialogContent>

        <Grid container>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('nombre')}
              variant="outlined"
              value={model.nombre}
              fullWidth
              onChange={event => onChange('nombre', event.target.value)}
              helperText={'Nombre'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('ciudad')}
              variant="outlined"
              value={model.ciudad}
              fullWidth
              onChange={event => onChange('ciudad', event.target.value)}
              helperText={'Ciudad'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('estado')}
              variant="outlined"
              value={model.estado}
              fullWidth
              onChange={event => onChange('estado', event.target.value)}
              helperText={'Estado'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('token')}
              variant="outlined"
              value={model.token}
              fullWidth
              onChange={event => onChange('token', event.target.value)}
              helperText={'Token'}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              error={errors.includes('descripcion')}
              variant="outlined"
              value={model.descripcion}
              fullWidth
              multiline
              onChange={event => onChange('descripcion', event.target.value)}
              helperText={'Descripción'}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                               name="gilad"/>}
              label="activa"
            />
          </Grid>
        </Grid>



        <Grid container>
          <Grid item md={3} xs={3}>
            <Button style={{backgroundColor: '#36bea1', color: 'white', minWidth: "190px"}} component="label">
              EDITAR
              <input type="file" accept="image/x-png,image/gif,image/jpeg"
                     style={{display: "none"}}
                     onChange={event => event.target.files.length && onChange('imagen', event.target.files[0])}
              />
            </Button>
          </Grid>

          <Grid item md={9} xs={9}>
            <Crop
              url={imagen instanceof File ? URL.createObjectURL(imagen) : imagen}
              onChange={(_imageFile) => {
                if (_imageFile) {
                  onChange('imagenCrop', _imageFile);
                }
              }}
              cropProp={{unit: "%", width: 100, aspect: 16 / 3}}
            />
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{color: 'white', backgroundColor: '#232e5c'}}>
          Cancelar
        </Button>
        {
          saving
            ?
            <CircularProgress style={{color: '#232e5c'}}/>
            :
            <Button onClick={handleAccept} style={{color: 'white', backgroundColor: '#232e5c'}} autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default HomeDialogView
