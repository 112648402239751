/*
  PROPS:
  - data: array of objects containing the table content (rows)
  - header: array of objects containing the headers content [{ prop, name }, ...]
  - extraRows: array of objects/columns that must be added (programatically) to every row 
      [{
        name, // (string) shown in header
        prop, // (string) id of header
        cell, // callback that must return what will be rendered in the cell (row) => <div>{ row.name }</div>
      },
      ...]
  - editable: (boolean) if true the table displays an edit column
  - route: (callback) if the table is editable the route indicates the path to the edit view
  - paginated: (boolean) if true the table includes the pagination at the bottom
  - filter: (callback or array of strings(props)) // used to filter through the searchbox
      callback: (item, searchtext) => Boolean 
      array of strings (props): a default filter callback is used to filter using the props provided to do the comparisons
  - mainField: (string) prop that will be used in every row to show a cell as a head (default: header[0])
  - placeholder: (string) placeholder in searchbox
*/
// TODO: add proptypes
import React, {useEffect, useState} from "react";
import orderBy from "lodash/orderBy";
import {Grid, Table, TableBody, TableHead, TablePagination, TableRow, TextField,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Searchbox from "./../SearchBox";
import Row from "./Row/index.js";
import HeaderCell from "./HeaderCell";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    cursor: "pointer",
  },
  icon: {
    color: "#a1a1a1",
  },
  searchbox: {
    marginBottom: "10px",
  },
  table: {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #afafaf",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
}));

// updates the data array adding the new 'extra rows' and headers
const addExtraRows = (extraRows, data, header) => {
  const newData = data.map((record) => ({...record})); // necessary in case objects are non-extensible
  const newHeader = [...header];
  extraRows.forEach((extraRow) => {
    data.forEach((row, index) => {
      // if the extraRows contains a prop that is already specified in header, we ignore it
      if (!header.map((h) => h.prop).includes(extraRow.prop)) {
        newData[index][extraRow.prop] = extraRow.cell(row);
      }
    });
  });
  extraRows.forEach((extraRow) => {
    // if the extraRows contains a prop that is already specified in header, we ignore it
    if (!header.map((h) => h.prop).includes(extraRow.prop)) {
      if (extraRow.pushStart) {
        newHeader.unshift({
          name: extraRow.name,
          prop: extraRow.prop,
        });
      } else {
        newHeader.push({
          name: extraRow.name,
          prop: extraRow.prop,
        });
      }
    }
  });
  return {
    modifiedData: newData,
    modifiedHeader: newHeader,
  };
};

// filter used by the searchbox
const defaultFilter = (item, props, searchtext) =>
  props.some(
    (prop) =>
      item[prop] && item[prop].toString().toUpperCase().includes(searchtext)
  );

const filterData = (data, filter, searchText) => {
  if (Array.isArray(filter)) {
    // the 'filter' can be an array of properties to filter by
    data = data.filter((item) => defaultFilter(item, filter, searchText));
  } else {
    // or it can be a callback to use as a customized filter
    data = data.filter((item) => filter(item, searchText));
  }
  return data;
};

const OrderableTable = ({
                          data: originalData,
                          header,
                          mainField,
                          extraRows,
                          route,
                          className,
                          editable,
                          paginated,
                          placeholder,
                          filter,
                          rowsPerPage: rows,
                          _page,
                          onChangePage,
                          initDate = new Date(),
                          endDate = new Date(),
                          onChangeDate,
                          onClick,
                        }) => {
  const classes = useStyles();
  // indicates the column which must be sorted
  const [columnToSort, setColumnToSort] = useState("");
  // indicates whether the sorting must be asc or desc
  const [asc, setAsc] = useState(true);
  // used in pagination
  const [rowsPerPage, setRowsPerPage] = useState(rows || 5);
  // used in pagination
  const [page, setPage] = useState(_page || 0);
  // to filter
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setRowsPerPage(rows || 25);
  }, [rows]);

  let data = [...originalData];

  if (extraRows) {
    const {modifiedData, modifiedHeader} = addExtraRows(
      extraRows,
      data,
      header
    );
    data = modifiedData;
    header = modifiedHeader;
  }

  mainField =
    mainField || (header ? (header.length ? header[0].prop : null) : null);

  data = columnToSort
    ? orderBy(data, columnToSort, asc ? "asc" : "desc")
    : data;

  if (filter && searchText) {
    // if there is a filter as prop then we filter the data
    data = filterData(data, filter, searchText);
  }

  const handleSort = (columnName) => {
    setColumnToSort(columnName);
    setAsc(columnToSort === columnName ? !asc : true);
  };

  if (!originalData || !originalData.length) return null;

  return (
    <div className={className}>
      {filter ? (
        initDate.getTime() !== endDate.getTime() ?
          <Grid container>
            <Grid item lg={6} sm={6} xl={6} xs={6} style={{marginTop: '1%'}}>
              <Searchbox style={{margin: '1%'}}
                className={classes.searchbox}
                placeholder={placeholder}
                onChange={(e) => {
                  setPage(0);
                  setSearchText(e.target.value.trim().toUpperCase());
                }}
              />
            </Grid>
            <Grid item lg={3} sm={3} xl={3} xs={3} style={{marginTop: '1%'}}>
              <TextField
                id="date"
                label="Desde"
                type="date"
                variant="outlined"
                defaultValue={moment(Date.parse(initDate)).locale('es-mx').format('YYYY-MM-DD').toString()}
                className={classes.textField}
                onChange={event => onChange('initDate', event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item lg={3} sm={3} xl={3} xs={3} style={{marginTop: '1%'}}>
              <TextField
                id="date"
                label="Hasta"
                type="date"
                variant="outlined"
                defaultValue={moment(Date.parse(endDate)).locale('es-mx').format('YYYY-MM-DD').toString()}
                className={classes.textField}
                onChange={event => onChange('endDate', event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

          </Grid> : <Searchbox
            className={classes.searchbox}
            placeholder={placeholder}
            onChange={(e) => {
              setPage(0);
              setSearchText(e.target.value.trim().toUpperCase());
            }}
          />
      ) : null}
      <Table className={classes.table}>
        <TableHead className={classes.header}>
          <TableRow>
            {header.map((field, index) => {
              const {name, prop} = field;
              return (
                <HeaderCell
                  key={`thc-${index}`}
                  sorted={columnToSort === prop}
                  direction={asc ? "asc" : "desc"}
                  name={name}
                  onClick={() => handleSort(prop)}
                />
              );
            })}
            {editable ? <HeaderCell name="Editar"/> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {(paginated
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
          ).map((rowData, index) => (
            <Row
              key={`tr-${rowData.key || rowData.id || index}`}
              data={rowData}
              headers={header}
              route={route}
              editable={editable}
              mainField={mainField}
            />
          ))}
        </TableBody>
      </Table>
      {paginated &&
      data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .length ? (
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(_, page) => {
            setPage(page);
            onChangePage(page);
          }}
          onChangeRowsPerPage={(e) => {
            setRowsPerPage(e.target.value);
            setPage(0);
          }}
          labelDisplayedRows={({from, to, count}) =>
            `${from}-${to === -1 ? count : to} de ${count}`
          }
          labelRowsPerPage={"Renglones por página"}
        />
      ) : null}
    </div>
  );
};

export default OrderableTable;
