import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles, Chip,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import DriveEta from "@material-ui/icons/DriveEta";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { Checkbox, IconButton } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import ListIcon from "@material-ui/icons/List";
import {CancelRounded, Fireplace, FitnessCenter, ImportContacts} from "@material-ui/icons";
import InboxIcon from '@material-ui/icons/Inbox';
import CachedIcon from '@material-ui/icons/Cached';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

const Budget = ({ title, info, data, filter, className, ...rest }) => {
  const classes = useStyles();

  const returnIcon = (item) => {
    switch (item) {
      case 'NUEV0':
        return <IconButton
          style={{ backgroundColor: "#e0e0e0", color: "#2a3d6b" }}
          onClick={() => filter()}
        >
          <NewReleases />
        </IconButton>
      case 'PAGADO':
        return <IconButton
          style={{ backgroundColor: "#213acc", color: "white" }}
          onClick={() => filter()}
        >
          <AttachMoneyIcon />
        </IconButton>
      case 'ENPROCESO':
        return <IconButton
          style={{ backgroundColor: "#c9500e", color: "white" }}
          onClick={() => filter()}
        >
          <CachedIcon />
        </IconButton>
      case 'TERMINADO':
        return  <IconButton
          style={{ backgroundColor: "#3ecc3b", color: "white" }}
          onClick={() => filter()}
        >
          <CheckCircle />
        </IconButton>
      case 'ENVIADO':
        return <IconButton
          style={{ backgroundColor: "#008000", color: "white" }}
          onClick={() => filter()}
        >
          <EmailIcon />
        </IconButton>
      case 'CANCELADO':
        return <IconButton
          style={{ backgroundColor: "#c93030", color: "white" }}
          onClick={() => filter()}
        >
          <DeleteIcon />
        </IconButton>
      case 'PHOTOBOOK':
        return <IconButton
          style={{ backgroundColor: "#2a3d6b", color: "white" }}
          onClick={() => filter()}
        >
          <ImportContacts />
        </IconButton>
      case 'KROMABOX':
        return <IconButton
          style={{ backgroundColor: "#2a3d6b", color: "white" }}
          onClick={() => filter()}
        >
          <InboxIcon />
        </IconButton>
      case 'KROMALUXE':
        return <IconButton
          style={{ backgroundColor: "#2a3d6b", color: "white" }}
          onClick={() => filter()}
        >
          <Fireplace />
        </IconButton>
      case 'ALL':
        return <IconButton
          style={{ backgroundColor: "#2a3d6b", color: "white" }}
          onClick={() => filter()}
        >
          <ListIcon />
        </IconButton>
    }
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item  lg={8} sm={8} xl={8} xs={8}>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {data}
            </Typography>
          </Grid>
          <Grid item  lg={4} sm={4} xl={4} xs={4} style={{textAlign:"end"}}>
            {returnIcon(info)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
};

export default Budget;
