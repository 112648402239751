import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import app from "../../firebase";


const HomeDialogView = ({open, title, onAccept, onCancel, onChange, model, saving}) => {

  const db = app.firestore();
  const {imagen = ''} = model;
  const [errors, setErrors] = useState([]);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({width: 900, height: 900});
  const [productos, setProductos] = useState();

  useEffect(async () => {
    try {
      db.collection('producto').onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProductos(data);
      })
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleAccept = () => {

    let _errors = [];

    if (!model.activo) {
      model.activo = false;
    }

    if (!model.nombre)
      _errors.push("nombre");
    if (!model.descripcion)
      _errors.push("descripcion");
    if (!model.precio)
      _errors.push("precio");
    if (!model.minimo)
      _errors.push("minimo");
    if (!model.maximo)
      _errors.push("maximo");

    setErrors(_errors);

    if (_errors.length) {
      console.log(errors);
      return;
    }
    onAccept();
  };


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const showCroppedImage = useCallback(async (picture) => {
    try {
      return await getCroppedImg(
        picture,
        croppedAreaPixels,
        rotation
      );

    } catch (e) {
      console.error(e);
      return null;
    }
  }, [croppedAreaPixels, rotation]);

  const handleBeforeSubmit = async () => {
    try {
      let _images = [];
      let image;


      if (model.imagen) {
        const img = await showCroppedImage(model.imagen);


        if (img.size > 200000) {
          const url = await getUrl(img);

          const resp = await fetch(url);
          const blob = await resp.blob();
          const file = new File([blob], "file", {type: "image/png"});
          const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 600,
            useWebWorker: true
          };

          try {
            const compressedFile = await imageCompression(file, options);
            image = compressedFile;
          } catch (error) {
            console.log(error);
          }
        } else {
          image = img;
        }


        setCrop({x: 0, y: 0});
        setZoom(1);
        setCroppedAreaPixels(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Agregar {title}</DialogTitle>
      <DialogContent>

        <Grid container spacing={2}>
          <Grid item xs={5}>

            {productos !== undefined ? <Select
              labelId="idProductoLabel"
              id="idProducto"
              label="Producto"
              variant="outlined"
              value={model.idProducto}
              fullWidth
              onChange={event => onChange('idProducto', event.target.value)}
            >
              {productos.map((item) => {
                  return <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>
                }
              )}
            </Select> : ""}
          </Grid>

          <Grid item xs={5}>
            <TextField
              error={errors.includes('nombre')}
              variant="outlined"
              value={model.nombre}
              fullWidth
              label="Nombre"
              onChange={event => onChange('nombre', event.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <FormControlLabel
              control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                               name="gilad"/>}
              label="activa"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={errors.includes('descripcion')}
              variant="outlined"
              value={model.descripcion}
              fullWidth
              multiline
              label="Descripción"
              onChange={event => onChange('descripcion', event.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              error={errors.includes('precio')}
              variant="outlined"
              value={model.precio}
              fullWidth
              label="Precio"
              type="number"
              onChange={event => onChange('precio', event.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              error={errors.includes('minimo')}
              variant="outlined"
              value={model.minimo ? model.minimo.replace('.', '') : model.minimo}
              fullWidth
              type="number"
              label="Fotos Mínimas"
              onKeyDown={(event) => event.key === '.' ? event.preventDefault() : event.key}
              onChange={event => onChange('minimo', event.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              error={errors.includes('maximo')}
              variant="outlined"
              value={model.maximo ? model.maximo.replace('.', '') : model.maximo}
              fullWidth
              type="number"
              label="Fotos Máximas"
              onKeyDown={(event) => event.key === '.' ? event.preventDefault() : event.key}
              onChange={event => onChange('maximo', event.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              error={errors.includes('x')}
              variant="outlined"
              value={model.x}
              fullWidth
              type="number"
              label="Alto de la imagen "
              onChange={event => onChange('x', event.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              error={errors.includes('y')}
              variant="outlined"
              value={model.y}
              fullWidth
              type="number"
              label="Ancho de la imagen "
              onChange={event => onChange('y', event.target.value)}
            />
          </Grid>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{color: 'white', backgroundColor: '#232e5c'}}>
          Cancelar
        </Button>
        {
          saving
            ?
            <CircularProgress style={{color: '#232e5c'}}/>
            :
            <Button onClick={handleAccept} style={{color: 'white', backgroundColor: '#232e5c'}} autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default HomeDialogView
