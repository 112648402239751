import React, {useEffect, useState} from "react";
import clsx from "clsx";
import Table from "../../components/Table";
import {Card, Chip, IconButton, makeStyles,} from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import {CancelRounded, Visibility} from "@material-ui/icons";
import {useFirestoreConnect} from "react-redux-firebase";
import moment from "moment";
import 'moment/locale/es-mx'
import CancelDialog from "../../components/CancelDialog";
import MessageDialogView from "./Dialog";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DraftsIcon from '@material-ui/icons/Drafts';
import firebase from "../../firebase";

const header = [];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const chips = {
  NUEVO: (
    <Chip
      icon={<NewReleases style={{color: "white"}}/>}
      size="small"
      label="Nuevo"
      clickable
      style={{color: "white", backgroundColor: "black"}}
    />
  ),
};

const Results = ({
                   className,
                   handleSync,
                   handleEdit,
                   handleDelete,
                   data,
                   onClick,
                 }) => {
  useFirestoreConnect([
    {
      collection: "chat"
    },
  ]);

  const classes = useStyles();
  const [model, setModel] = useState([]);
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [catchInicio, setCatchInicio] = useState("");
  const [catchFin, setCatchFin] = useState("");
  const [mensajes, setMensajes] = useState([]);
  const [openDialogMessage, setOpenDialogMessage] = useState(false);

  useEffect(() => {

  }, [catchInicio, catchFin])


  const dateRow = (date) => moment(Date.parse(date.toDate())).locale('es-mx').format('LLLL')
  const cancelar = () => {
    try {
      changeEstatus(model.estatus, model.id, true)
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogCancel(false);
      setModel([]);
    }
  }

  const mensajesRow = (mensajes, row) => {

    let icon = "";
    let style = "";
    let label = "";

    if (mensajes.length > 0) {
      mensajes.map((mens) => {
        if (mens.leido === null) {
          icon = <ContactMailIcon style={{color: "white"}}/>
          style = {color: "white", backgroundColor: "red"}
        } else {
          icon = <DraftsIcon style={{color: "white"}}/>
          style = {color: "white", backgroundColor: "green"}
        }
      })

      return <IconButton
        style={style}
        onClick={() => {
          setMensajes(mensajes);
          setOpenDialogMessage(true);
          setModel(row);
        }}>
        {icon}
      </IconButton>
    } else {
      return ""
    }
  }

  console.log(data);

  return (
    <Card className={clsx(classes.root, className)}>
      <Table
        onClick={onClick}
        header={header}
        data={data.sort((a, b) => a.fecha < b.fecha ? 1 : -1)}
        paginated
        //initDate={inicio}
        //endDate={fin}
        filter={[
          "folioPedido",
          "nombreCliente",]}
        extraRows={[
          {
            prop: "fecha",
            name: "Fecha",
            cell: (row) => (
              dateRow(row.fecha)
            ),
          },{
            prop: "folioPedido",
            name: "Folio del Pedido",
            cell: (row) => (
              row.folioPedido
            ),
          }, {
            prop: "nombreCliente",
            name: "Nombre del Cliente",
            cell: (row) => (
              row.nombreCliente
            ),
          },
          {
            prop: "actions",
            name: "",
            cell: (row) => (
              <>
                <IconButton
                  style={{backgroundColor: "#00b3f8", color: "white"}}
                  onClick={() => {
                    setOpenDialogMessage(true);
                    setModel(row);
                  }}>
                  <DraftsIcon/>
                </IconButton>

              </>
            ),
          },
        ]}
      />

      <MessageDialogView
        open={openDialogMessage}
        mensajes={model.mensajes}
        model={model}
        onCancel={() => {
          setOpenDialogMessage(false);
          setMensajes([]);
        }}
      />

      <CancelDialog
        open={openDialogCancel}
        onAcceptCancel={cancelar}
        info={"el pedido con folio " + model.folio}
        onCancel={() => {
          setOpenDialogCancel(false);
          setModel([]);
        }}
      />
    </Card>
  );
};

export default Results;
