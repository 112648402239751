import React, {useEffect, useState} from "react";
import "./styles.css";
import {BrowserRouter as Router, Redirect, Switch} from "react-router-dom";
import theme from "./components/Theme";
import {ThemeProvider} from "@material-ui/core/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RouteAuth from "./components/RouteAuth";
import {useSelector} from "react-redux";
import {isEmpty, isLoaded} from "react-redux-firebase";
import {AdminView, LogInView, NoticePrivacy, ShowFormView, WebView} from "./views";
import CustomerListView from "./views/CustomerListView";
import OrderListView from "./views/Dashboard";
import Ciudades from "./views/Ciudad";
import Anuncio from "./views/Anuncio";
import Forms from "./views/Forms";
import Etiqueta from "./views/Etiqueta";
import Laboratorio from "./views/Laboratorio";
import Producto from "./views/Producto";
import Paquete from "./views/Paquete";
import Usuario from "./views/Usuario";
import Chat from "./views/Chat";
import OrderView from "./views/OrderView";
import Services from "./services/index";
import Token from "./views/token";

const containerStyle = {
  backgroundColor: "#fdfdfd",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  minHeight: "100vh",
};

const bodyStyles = {
  margin: 0,
  padding: 0,
};

export default () => {
  const [userAuth, setUserAuth] = useState();
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.firebase.auth);

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      const getUser = async () => {
        try {
          const user = await Services.getDocumentById("user", auth.uid);

          if (user.exists) {
            setUserAuth({...user.data(), id: user.id});
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      getUser();
    }
  }, [auth]);

  if (!isLoaded(auth)) return null;
  let logotipo = 'https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/logokroma.png?alt=media&token=3da2d89d-845e-4b7e-92b7-9d3b19040244';
  return (
    <div style={containerStyle}>
      <ThemeProvider theme={theme}>
        <Router>
          {isEmpty(auth) && !(window.location.pathname).includes('/cuestionario') && <Redirect to="/login"/>}
          {!isEmpty(auth) && !loading ? <Header hideHeaderLinks/> : null}
          <div style={!isEmpty(auth) && !loading ? bodyStyles : null}>
            <Switch>
              {userAuth && userAuth.rol === "administrador" ? (
                <RouteAuth exact path="/" component={OrderListView}/>
              ): <RouteAuth exact path="/noticePrivacy" component={NoticePrivacy}/>
              }


              <RouteAuth exact path="/login" component={LogInView}/>
              <RouteAuth exact path="/admin" component={AdminView}/>
              <RouteAuth exact path="/token" component={Token}/>
              <RouteAuth exact path="/cuestionario/:id" component={ShowFormView}/>

              {userAuth && userAuth.rol === "administrador" && (
                <RouteAuth exact path="/clientes" component={CustomerListView}/>
              )}
              {userAuth && userAuth.rol === "administrador" && (
                <RouteAuth exact path="/pedido" component={OrderView}/>
              )}
              {userAuth && userAuth.rol === "administrador" && (
                <>
                  <RouteAuth exact path="/formularios" component={Forms}/>
                  <RouteAuth exact path="/anuncios" component={Anuncio}/>
                  <RouteAuth exact path="/etiquetas" component={Etiqueta}/>
                  <RouteAuth exact path="/laboratorio" component={Laboratorio}/>
                  <RouteAuth exact path="/paquetes" component={Paquete}/>
                  <RouteAuth exact path="/productos" component={Producto}/>
                  <RouteAuth exact path="/usuario" component={Usuario}/>
                  <RouteAuth exact path="/chat" component={Chat}/>
                </>
              )}

              {userAuth && userAuth.rol === "administrador" && (
                <RouteAuth exact path="/pedidos" component={OrderListView}/>
              )}
              <RouteAuth exact path="/noticePrivacy" component={NoticePrivacy}/>
              <RouteAuth exact path="/web" component={WebView}/>
            </Switch>
          </div>
          {!isEmpty(auth) && !loading ? <Footer/> : null}
        </Router>
      </ThemeProvider>
    </div>
  );
};
