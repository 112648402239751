import React, {useEffect, useState} from "react";
import {Box, Chip, Container, Grid, makeStyles} from "@material-ui/core";
import Page from "../../components/Page";
import Budget from "./Budget";
import Results from "./Results";
import {useSelector} from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import app from "../../firebase";
import {DeleteForever} from "@material-ui/icons";
import Services from "../../services";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = ({history}) => {
  const db = app.firestore();

  const [openCombo, setComboOpen] = React.useState(false);
  const [pedidos, setPedidos] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [pedido, setPedido] = React.useState({});
  const [filter, setFilter] = React.useState(["estatus", " "]);
  const [mensajes, setMensajes] = React.useState([]);

  const [todos, setTodos] = useState(0);
  const [nuevos, setNuevos] = useState(0);
  const [pagados, setPagados] = useState(0);
  const [pagadosText, setPagadosText] = useState('0');
  const [enProceso, setEnProceso] = useState(0);
  const [terminados, setTerminados] = useState(0);
  const [terminadosText, setTerminadosText] = useState('0');
  const [enviadosText, setEnviadosText] = useState('0');
  const [nuevosText, setNuevosText] = useState('0');
  const [cancelados, setCancelados] = useState(0);

  const [pedidoPhotoBook, setPedidoPhotoBook] = useState(0);
  const [pedidoKromaBox, setPedidoKromaBox] = useState(0);
  const [pedidoKromaLuxe, setPedidoKromaLuxe] = useState(0);

  const [pedidosViejos, setPedidosViejos] = useState([]);

  let finSec = Math.round(new Date().getTime() / 1000.0);
  let inicioSec = finSec - (750 * 3600);

  let fin = new Date(finSec * 1000)
  let inicio = new Date(inicioSec * 1000)

  const handleComboClose = () => {
    setComboOpen(false);
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  const pedidosSelector = useSelector(
    (state) => state.firestore.ordered.pedido
  );
  const classes = useStyles();

  useEffect(() => {

    db.collection("pedido")
      .where("estatus", "!=", "NUEVOAPP")
      .onSnapshot(async snapshots => {

        const _pedidos = snapshots.docs.map(doc => ({...doc.data(), id: doc.id}));

        let i = 0;
        let _photoBook = 0;
        let _kromaBox = 0;
        let _kromaLuxe = 0;
        let _nuevos = 0;
        let _pagado = 0;
        let _enProceso = 0;
        let _terminados = 0;
        let _cancelados = 0;
        let _enviados = 0;

        let _pedidosViejos = [...pedidosViejos];
        let _idPedidos = [];

        for (let i = 0; i < _pedidos.length;){
          const pedido = _pedidos[i]

          //const chatDocs = await Services.getCollectionWhere("chat", "idPedido", pedido.id)
          //const salas = await Services.getCollectionWhere("salas", "idPedido", pedido.id)
          //_pedidos[i].mensajes = chatDocs.docs.map(doc => ({...doc.data(), id: doc.id}));
          //_pedidos[i].mensajes = salas.docs.map(doc => ({...doc.data(), id: doc.id}));
          _pedidos[i].mensajes = [];//salas.docs.map(doc => ({...doc.data(), id: doc.id}));
          i++;
          switch (pedido.producto.tipo) {
            case "PHOTOBOOK":
              _photoBook++;
              break;
            case "KROMABOX":
              _kromaBox++;
              break;
            case "KROMALUXE":
              _kromaLuxe++;
              break;
          }

          switch (pedido.estatus) {
            case "NUEVO":
              _nuevos++;
              break;
            case "PAGADO":
              _pagado++;
              break;
            case "ENPROCESO":
              _enProceso++;
              break;
            case "TERMINADO":
              _terminados++;
              break;
            case "ENVIADO":
              _enviados++;
              break;
            case "CANCELADO":
              _cancelados++;
              break;
          }


          if (pedido.evidenciaEliminadas === undefined) {
            if (pedido.fechaTerminado !== undefined) {
              if (pedido.fechaTerminado.seconds < inicioSec) {
                _idPedidos.push(pedido.id)
                _pedidosViejos = [..._pedidosViejos, pedido];
              }
            }

            if (pedido.fechaEnviado !== undefined) {
              if (pedido.fechaEnviado.seconds < inicioSec && !_idPedidos.includes(pedido.id)) {
                _pedidosViejos = [..._pedidosViejos, pedido];
              }
            }

            if (pedido.fechaCancelado !== undefined) {
              if (pedido.fechaCancelado.seconds < inicioSec && !_idPedidos.includes(pedido.id)) {
                _pedidosViejos = [..._pedidosViejos, pedido];
              }
            }
          }
        };

        setPedidosViejos(_pedidosViejos);

        setPedidoPhotoBook(_photoBook);
        setPedidoKromaBox(_kromaBox);
        setPedidoKromaLuxe(_kromaLuxe);
        setPagados(_pagado);

        setEnProceso(_enProceso);
        setTerminados(_terminados);
        setCancelados(_cancelados);
        setNuevos(_nuevos);

        setPagadosText(_pagado)
        setTerminadosText(_terminados)
        setEnviadosText(_enviados)
        setNuevosText(_nuevos)

        setTodos(i);
        setPedidos(_pedidos);
      })
  }, []);

  const deletePedido = async () => {
    db.collection("pedido").doc(pedido.id).update({estatus: "CANCELADO"});

    setOpenDelete(false);
  };

  const filterNuevo = () => {
    setFilter(["estatus", "NUEVO"]);
  };
  const filterPagado = () => {
    setFilter(["estatus", "PAGADO"]);
  };
  const filterEnProceso = () => {
    setFilter(["estatus", "ENPROCESO"]);
  };
  const filterTerminado = () => {
    setFilter(["estatus", "TERMINADO"]);
  };
  const filterEnviado = () => {
    setFilter(["estatus", "ENVIADO"]);
  };
  const filterCancelado = () => {
    setFilter(["estatus", "CANCELADO"]);
  };
  const filterPhotoBook = () => {
    setFilter(["tipo", "PHOTOBOOK"]);
  };
  const filterKromaLuxe = () => {
    setFilter(["tipo", "KROMALUXE"]);
  };
  const filterKromaBox = () => {
    setFilter(["tipo", "KROMABOX"]);
  };
  const filterAll = () => {
    setFilter(["estatus", " "]);
  };

  const deletePackageImages = (rows) => {
    rows.map((row) => {

      if (row.evidencias !== undefined && row.evidencias.length > 0) {
        row.evidencias.map((imageItem) => {
          onDeleteImage(0, imageItem.imagePath)
        })
      }
      db.collection("pedido").doc(row.id).update({evidenciaEliminadas: true});

    })
  };

  const onDeleteImage = async (index, data) => {
    try {
      await Services.deleteFile(data)
      return data;
    } catch (error) {
    }
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <DeleteDialog
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onClose={() => setOpenDelete(false)}
        onAcceptDelete={deletePedido}
      />
      <Container maxWidth={false}>
        <Grid container spacing={3}>

          {
            pedidosViejos.length > 0 ?
              <Grid item lg={12} sm={12} style={{textAlign: "right"}}>
                <Chip
                  icon={<DeleteForever style={{color: "white"}}/>}
                  size="small"
                  label={"Limpiar imagenes de " + pedidosViejos.length + " pedidos terminados "}
                  clickable
                  onClick={() => {
                    deletePackageImages(pedidosViejos)
                  }}
                  style={{color: "white", backgroundColor: "red"}}
                />
              </Grid> : ""
          }

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Todos"}
              info={"ALL"}
              data={todos}
              filter={filterAll}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"PhotoBook"}
              info={"PHOTOBOOK"}
              data={pedidoPhotoBook}
              filter={filterPhotoBook}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Kromaluxe"}
              info={"KROMALUXE"}
              data={pedidoKromaLuxe}
              filter={filterKromaLuxe}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"KromaBox"}
              info={"KROMABOX"}
              data={pedidoKromaBox}
              filter={filterKromaBox}
            />
          </Grid>
          {/**
           Estatus del pedido
           */}
          <Grid item lg={2} sm={4} xl={2} xs={6}>
            <Budget
              title={"Nuevos"}
              info={"NUEV0"}
              data={nuevosText}
              filter={filterNuevo}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={6}>
            <Budget
              title={"Pagados"}
              info={"PAGADO"}
              data={pagadosText}
              filter={filterPagado}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={6}>
            <Budget
              title={"En proceso"}
              info={"ENPROCESO"}
              data={enProceso}
              filter={filterEnProceso}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={6}>
            <Budget
              title={"Terminados"}
              info={"TERMINADO"}
              data={terminadosText}
              filter={filterTerminado}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={12}>
            <Budget
              title={"Enviados"}
              info={"ENVIADO"}
              data={enviadosText}
              filter={filterEnviado}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={6}>
            <Budget
              title={"Cancelados"}
              info={"CANCELADO"}
              data={cancelados}
              filter={filterCancelado}
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          {filter[1] === " " ? (
            //regresa todos
            <Results
              onClick={(row) => {
                history.push({
                  pathname: "/pedido",
                  state: {
                    id: row.id,
                  },
                });
              }}
              data={pedidos}
              handleDelete={(row) => {
                setOpenDelete(true);
                setPedido(row);
                // setUser(row);
              }}
            />
          ) : (//si es por tipo KROMABOX, PHOTOBOOK,
            filter[0] === "tipo" ? (<Results
              onClick={(row) => {
                history.push({
                  pathname: "/pedido",
                  state: {
                    id: row.id,
                  },
                });
              }}
              data={pedidos.filter((a) => a.producto.tipo === filter[1])}
              handleDelete={(row) => {
                setOpenDelete(true);
                setPedido(row);
                // setUser(row);
              }}
            />) : (
              // si es por estatus
              <Results
                onClick={(row) => {
                  history.push({
                    pathname: "/pedido",
                    state: {
                      id: row.id,
                    },
                  });
                }}
                data={pedidos.filter((a) => a.estatus === "TERMINADO" ? a.estatus === filter[1] || a.estatus === "ENVIADO" : a.estatus === filter[1])}
                handleDelete={(row) => {
                  setOpenDelete(true);
                  setPedido(row);
                  // setUser(row);
                }}
              />)
          )}
        </Box>
        <Box height="60"/>
      </Container>
    </Page>
  );
};

export default Dashboard;
