import React, {useEffect, useState} from "react";
import {Box, Chip, Container, Grid, makeStyles} from "@material-ui/core";
import Page from "../../components/Page";
import Results from "./Results";
import {useSelector} from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import app from "../../firebase";
import Services from "../../services";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const IndexChat = ({history}) => {
  const db = app.firestore();
  const [salas, setSalas] = React.useState([]);
  const [sala, setSala] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);

  const pedidosSelector = useSelector(
    (state) => state.firestore.ordered.pedido
  );
  const classes = useStyles();

  useEffect(() => {
    try {
      db.collection("salas")
        .onSnapshot(async snapshot => {
          const _salas = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
          for (let i = 0; i < _salas.length; i++) {
            let _sala = _salas[i];
            let mensajesNoVistos = 0;

            const mensajes = await Services.getCollectionWhere("mensajes", "idSala", _sala.id)
            _salas[i].mensajes = mensajes.docs.map(doc => ({...doc.data(), id: doc.id}));
            _salas[i].mensajesCantidad = mensajes.docs.length;

            for(let j = 0; j < _salas[i].mensajes.length; j ++){
              let _mensajes = _salas[i].mensajes[j];
              if(_mensajes.leido === undefined || _mensajes.leido === 0){
                mensajesNoVistos++;
              }
            }
            _salas[i].mensajesNoVistos = mensajesNoVistos;
          }
          setSalas(_salas);
        });
    }catch (error){
      console.log(error)
    }
  }, []);

  const deletePedido = async () => {
    db.collection("pedido").doc(pedido.id).update({estatus: "CANCELADO"});

    setOpenDelete(false);
  };

  return (
    <Page className={classes.root} title="Mensajes">
      <DeleteDialog
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onClose={() => setOpenDelete(false)}
        onAcceptDelete={deletePedido}
      />
      <Container maxWidth={false}>
        <Box mt={3}>
            <Results
              onClick={(row) => {
                history.push({
                  pathname: "/pedido",
                  state: {
                    id: row.id,
                  },
                });
              }}
              data={salas.sort((a, b) => a.fecha > b.fecha ? 1 : -1)}
              handleDelete={(row) => {
                setOpenDelete(true);
                setSala(row);
              }}
            />
        </Box>
        <Box height="60"/>
      </Container>
    </Page>
  );
};

export default IndexChat;
