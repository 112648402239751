import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import {Box, Card, Chip, IconButton, makeStyles,} from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import {Link} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './Dialog'
import Services from '../../services';
import DeleteDialog from '../../components/DeleteDialog';
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormsDialog from "./FormsDialogView";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import TableIconButton from "../../components/TableIconButton";


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({className, handleSync, handleEdit, handleDelete, title, header, data, ...rest}) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogResultados, setOpenDialogResultados] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);

  const update = async () => {
    try {

      let data = {
        formButtom: model.formButtom,
        formMessaje: model.formMessaje,
        formName: model.formName,
        fecha: model.fecha.seconds !== undefined ? model.fecha : new Date(model.fecha),
        form: model.form,
        activo: model.activo,
      };

      await Services.updateDocument('formulario', model.id, data)

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
      setModel([]);
    }
  };

  const borrar = async () => {
    try {
      await Services.delete('formulario', model.id);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogDelete(false);
      setModel(false);
      setModel([]);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "descriocion", "uid"]} extraRows={[
        {
          prop: "estatus",
          name: "Estatus",
          cell: (row) => (
            <Link
              to={{
                pathname: `/formularios`,
                state: row,
              }}
            >
              <Box>
                {row.activo == true ? (
                  <Chip
                    icon={<NewReleases style={{color: "white"}}/>}
                    size="small"
                    label="Activo"
                    clickable
                    style={{color: "white", backgroundColor: "green"}}
                  />
                ) : <Chip
                  icon={<NewReleases style={{color: "white"}}/>}
                  size="small"
                  label="Inactivo"
                  clickable
                  style={{color: "white", backgroundColor: "red"}}
                />}

              </Box>
            </Link>
          ),
        },
        {
          prop: 'actions',
          name: '',
          cell: row => (
            <>
              <IconButton
                style={{backgroundColor: "#f0ed18", color: "white"}}
                onClick={() => {
                  setOpenDialogResultados(true);
                  setModel(row);
                }}>
                <EditIcon/>
              </IconButton>
              <IconButton
                style={{backgroundColor: "#00b3f8", color: "white"}}
                onClick={() => {
                  setOpen(true);
                  setModel(row);
                }}>
                <FormatListBulletedIcon/>
              </IconButton>
              <IconButton
                style={{backgroundColor: "#f02d1d", color: "white"}}
                onClick={() => {
                  setOpenDialogDelete(true);
                  setModel(row);
                }}>
                <DeleteIcon/>
              </IconButton>
              <Link to={"/cuestionario/" + row.id}>
              <IconButton style={{backgroundColor: '#09a000', color: "white"}}>
                <TableIconButton
                  color={'white'}
                  icon={VisibilityIcon}/>
              </IconButton>
            </Link>
            </>
          )
        }
      ]}
      />
      <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === 'image') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          } else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          } else {
            setModel({...model, [key]: value});
          }

        }}
        model={model}
        title={title}
      />
      <FormsDialog
        open={openDialogResultados}
        form={model}
        onAccept={update}
        onCancel={() => {
          setOpenDialogResultados(false);
          setModel({});
        }}
        onChange={(key, value) => {

          if (key === 'image') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          } else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          } else {
            setModel({...model, [key]: value});
          }
        }}
      />
      <DeleteDialog
        open={openDialogDelete}
        onAcceptDelete={borrar}
        onCancel={() => {
          setOpenDialogDelete(false);
          setModel({});
        }}
      />
    </Card>


  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
