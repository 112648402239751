import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import {Box, Card, Chip, IconButton, makeStyles,} from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import {Link} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './Dialog'
import Services from '../../services';
import DeleteDialog from '../../components/DeleteDialog';

const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },
  {
    prop: "descripcion",
    name: "Descripción",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({className, handleSync, handleEdit, handleDelete, title, data, ...rest}) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);
  const [deleteImages, setDeleteImages] = React.useState([]);
  const [imageFile, setImageFile] = React.useState(null);

  const update = async () => {
    try {

      let data = {
        nombre: model.nombre,
        descripcion: model.descripcion,
        activo: model.activo,
        carousel: []
      };

      if (imageFile instanceof File) {
        const url = await Services.uploadFile('producto/' + imageFile.name, imageFile);
        data.imagen = url;
        data.imagenPath = 'producto/' + imageFile.name;
      }

      if (model.carousel.length) {
        for (let i = 0; i < model.carousel.length; i++) {
          if (model.carousel[i].imagenCrop || model.carousel[i].imagen instanceof File) {
            const file = model.carousel[i].imagenCrop ? model.carousel[i].imagenCrop :  model.carousel[i].imagen;

            const url = await Services.uploadFile('producto/' + file.name, file);
            data.carousel[i] = {
              imagen: url,
              imagenPath: 'producto/' + file.name
            }
          } else {
            data.carousel[i] = {
              imagen: model.carousel[i].imagen,
              imagenPath: model.carousel[i].imagenPath
            }
          }
        }
      }

      if(deleteImages.length){
        let imageDeleted = "";
        deleteImages.map((image) => {
          if(image !== imageDeleted)
            imageDeleted = onDeleteImage( 0, image);
        })
      }

      await Services.updateDocument('producto', model.id, data)

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const borrar = async () => {
    try {
      onDeleteImage( 0,  model.imagenPath);
      if (model.carousel.length){
        model.carousel.map((image)=>{
          onDeleteImage( 0,  image.imagenPath);
        })
      }
      await Services.delete('producto', model.id);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogDelete(false);
      setModel([]);
    }
  };

  const onDeleteImage = async (index, data) =>{
    try {
      await Services.deleteFile(data)
      return data;
    }catch (error) {
    }
  }

  const rowActivo = async (model, type) => {
    let data = {
      activo: type
    };
    await Services.updateDocument('producto', model.id, data)
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "descripcion", "uid"]} extraRows={[
        {
          prop: 'image',
          name: 'Imagen',
          cell: row => (
            <img height={50} src={row.imagen}/>
          )
        }, {
          prop: "estatus",
          name: "Estatus",
          cell: (row) => (
            <Link
              to={{
                pathname: `/productos`,
                state: row,
              }}
            >
              <Box>
                {row.activo == true ? (
                  <Chip
                    icon={<NewReleases style={{color: "white"}}/>}
                    size="small"
                    label="Activo"
                    clickable
                    onClick={() => {
                      rowActivo(row, false)
                    }}
                    style={{color: "white", backgroundColor: "green"}}
                  />
                ) : <Chip
                  icon={<NewReleases style={{color: "white"}}/>}
                  size="small"
                  label="Inactivo"
                  clickable
                  onClick={() => {
                    rowActivo(row, true)
                  }}
                  style={{color: "white", backgroundColor: "red"}}
                />}

              </Box>
            </Link>
          ),
        },
        {
          prop: 'actions',
          name: '',
          cell: row => (
            <>
              <IconButton
                style={{backgroundColor: "#f0ed18", color: "white"}}
                onClick={() => {
                  setOpen(true);
                  setModel(row);
                }}>
                <EditIcon/>
              </IconButton>
              <IconButton
                style={{backgroundColor: "#f02d1d", color: "white"}}
                onClick={() => {
                  setOpenDialogDelete(true);
                  setModel(row);
                }}>
                <DeleteIcon/>
              </IconButton>
            </>
          )
        }
      ]}
      />
      <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value, indexImagen, oldImage) => {
          if (key === "carouselCrop") {
            if (oldImage) {
              setModel({...model, carousel: model.carousel.map((c, i) => i === indexImagen ? {...c, imagenCrop: value} : c) })
            } else {
              setModel({...model, carousel: [...model.carousel, {imagen: value }]})
            }
          } else if (key === 'imagen') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          } else if (key === 'imagenCrop') {
            setDeleteImages([...deleteImages, model.imagenPath]);
            setImageFile(value);
          } else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          } else {
            setModel({...model, [key]: value});
          }
        }}
        onDelete={(imagen, indice) => {
          setModel({...model, carousel: model.carousel.filter((c, i) => i !== indice)})
          if (imagen.imagenPath) {
            setDeleteImages([...deleteImages, imagen.imagenPath]);
          }
        }}
        model={model}
        title={title}
      />
      <DeleteDialog
        open={openDialogDelete}
        onAcceptDelete={borrar}
        info={model.nombre}
        onCancel={() => {
          setOpenDialogDelete(false);
          setModel({});
        }}
      />
    </Card>


  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
