import React, {useEffect, useState} from "react";
import {useFirestoreConnect,} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Box, Button, Container, makeStyles, Typography} from "@material-ui/core";
import Page from "../../components/Page";
import Dialog from './Dialog'
import Results from "./Results";
import Services from '../../services';
import app from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const title = "Paquetes";

const Dashboard = () => {

  useFirestoreConnect([
    {
      collection: "paquete",
      orderBy: [
        "idProducto"
      ]
    }
  ]);
  const db = app.firestore();
  const classes = useStyles();
  const [openCombo, setComboOpen] = useState(false);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState([]);
  const [open, setOpen] = useState(false);
  const handleComboClose = () => {
    setComboOpen(false);
  };
  const handleComboOpen = () => {
    setComboOpen(true);
  };


  const modelsSelector = useSelector(
    (state) => state.firestore.ordered.paquete
  );

  useEffect(async () => {
    try {
      if (modelsSelector) {
        let _models = [...modelsSelector];

        setModels(_models);
      }
    } catch (e) {
      console.log(e);
    }
  }, [modelsSelector]);

  const add = async () => {
    try {

      let data = {
        nombre: model.nombre === undefined ? "" : model.nombre,
        activo: model.activo,
        descripcion: model.descripcion === undefined ? "" : model.descripcion,
        minimo: model.minimo === undefined ? "" : model.minimo,
        maximo: model.maximo === undefined ? "" : model.maximo,
        idProducto: model.idProducto === undefined ? "" : model.idProducto,
        precio: model.precio === undefined ? "" : model.precio,
        tamano: model.x + "x" + model.y,
        x: model.x === undefined ? 0 : parseInt(model.x),
        y: model.y === undefined ? 0 : parseInt(model.y),
        fecha: new Date(),
      }

      if (model.carousel) {
        const url = await Services.uploadFile('paquete/' + model.imagen.name, model.imagen);
        data.carousel[0].imagenUrl = url;
        data.carousel[0].imagenPath = 'paquete/' + model.imagen.name;
        data.carousel[0].tipo = 'paquete';
      }

      await Services.addDocument('paquete', data);

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
      setModel([]);
    }
  };

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Box display="flex" p={1} mt={2}>
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={async () => {
                //const _paquetes = await Services.getCollectionWhere('paquete', 'idProducto', model.id);
                //setModel({...model, paquetes: _paquetes})
                setOpen(true);
              }}
              variant="contained"
            >
              Nuevo
            </Button>
          </Box>


        </Box>
        <Box mt={3}>
          <Results data={models} title={title}/>
        </Box>
        <Box height="60"></Box>


      </Container>
      <Dialog
        open={open}
        onAccept={add}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {

          console.log("Se preciono un boton", key, value);

          if (key === 'image') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          }
          else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          }
          else {
            setModel({...model, [key]: value});
          }
        }}
        onChangePaquete={(key, value, id) => {
          setModel({...model, paquetes: model.paquetes.map((p) => (p.id === id ? {...p, [key]: value} : p ))})
        }}
        model={model}
        title={title}
      />
    </Page>
  );
};

export default Dashboard;
