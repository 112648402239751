import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles, Button, Typography } from "@material-ui/core";
import Page from "../../components/Page";
import Results from "./Results";
import data from "./data";
import FormDialog from "./Modal.js";
import DeleteDialog from "../../components/DeleteDialog";
import SyncDialog from "./ModalSync.js";
import app from "../../firebase";
import axios from "axios";
import { Sync } from "@material-ui/icons";
import QRCode from "easyqrcodejs";
import UsersDialog from "./userDialogView";
import Services from "../../services";
import Alert from "../../components/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const title = "Clientes";

const CustomerListView = () => {
  const db = app.firestore();
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openSync, setOpenSync] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [relations, setRelations] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [deviceInfo, setInfo] = React.useState([]);
  const [urls, setUrls] = useState([]);
  const code = React.createRef();
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState("");

  const [mode, setMode] = useState("");
  useEffect(() => {
    let isMounted = true;
    /*   .where("activo", "==", "si") */

    db.collection("user")
      .where("rol", "==", "cliente")
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        if (isMounted) setUsers(data.filter((a) => a.activo != "no"));
      });

    return () => (isMounted = false);
  }, []);
  const add = async () => {
    if (saving) return;

    try {
      setSaving(true);

      const _user = { ...user };

      delete _user.confirmPassword;

      _user.rol = "cliente";

      const response = await Services.post("add-user", _user);

      if (response.data.success) {
        setMessage("Usuario guardado con éxito.");
        setOpen(false);
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } else if (response.data.error.code === "auth/email-already-exists") {
        setError(
          'Error al guardar el usuario, el "Correo electrónico" está en uso.'
        );
      } else {
        setError("Error al guardar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error);
      setError("Error al guardar el usuario, intentelo de nuevo.");
    } finally {
      setSaving(false);
    }
  };

  const update = async () => {
    if (saving) return;

    try {
      setSaving(true);

      const _user = { ...user };

      delete _user.confirmPassword;

      const response = await Services.post("update-user", _user);

      if (response.data.success) {
        setMessage("Usuario actulizado con éxito.");
        setOpen(false);
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } else if (response.data.error.code === "auth/email-already-exists") {
        setError(
          'Error al editar el usuario, el "Correo electrónico" está en uso.'
        );
      } else {
        setError("Error al editar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error);
      setError("Error al editar usuario, intentelo de nuevo");
    } finally {
      setSaving(false);
    }
  };

  const del = async () => {
    try {
      setDeleting(true);

      const response = await Services.post("delete-user", {
        id: deleteId,
      });

      if (!response.data.success) {
        setError("Error al borrar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error);
      setError("Error al borrar el usuario, intentelo de nuevo.");
    } finally {
      setDeleteId("");
      setDeleting(false);
    }
  };
  const deleteUser = async () => {
    user.activo = "no";
    user.borrado = app.firestore.Timestamp.now();
    console.log(user);
    db.collection("usuarios").doc(user.id).update(user);
    setOpenDelete(false);
  };
  /*   const deleteUser = async () => {
    const url2 =
      "https://us-central1-edesarrollos-iot.cloudfunctions.net/eliminar";
    try {
      const respo = await axios.post(url2, {
        uid: user.id,
        borrar: "usuario",
      });
      console.log("res:", respo.data);

      setOpenDelete(false);
    } catch (error) {
      alert(error);
    }
  }; */
  const getQR = (data2) => {
    try {
      if (data2.length && data2) {
        data2.forEach((d) => {
          new QRCode(code.current, {
            text: d.dispositivo,
            width: 256,
            height: 256,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
            dotScale: 1,
            onRenderingEnd: function (_, dataURL) {
              var index = urls.findIndex((x) => x == dataURL);
              if (index === -1) {
                urls.push(dataURL);
              } else {
                console.log("object already exists");
              }
              db.collection("dispositivos")
                .doc(d.dispositivo)
                .get()
                .then((querySnapshot) => {
                  const data = querySnapshot.data();
                  var index = deviceInfo.findIndex(
                    (x) => x.id_unico == data.id_unico
                  );
                  if (index === -1) {
                    deviceInfo.push(data);
                  } else {
                    console.log("object already exists");
                  }
                });
            },
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const syncRelations = (id) => {
    try {
      db.collection("relaciones")
        .where("usuario", "==", id)
        .get()
        .then((querySnapshot) => {
          const data2 = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          try {
            getQR(data2);
          } catch (e) {
            console.log(e);
          }
          setRelations(data2);
          syncDevices();
        });
    } catch (e) {
      console.log(e);
      console.log(id);
    }
  };

  const syncDevices = (row) => {
    try {
      db.collection("dispositivos")
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setDevices(data);
          console.log(data);
          if (deviceInfo.length > 0) {
            setOpenSync(true);
          }
        });
    } catch (e) {
      console.log(e);
      syncRelations(row.id);
    }
  };

  return (
    <Page className={classes.root}  title={title}>
      <Container maxWidth={false}>
        <div
          ref={code}
          style={{
            display: "none",
            visibility: "hidden",
            maxWidth: 0,
            maxHeight: 0,
          }}
        >
          <Typography variant="h5">{title}</Typography>
        </div>
        <Box align="right">
          <Button
            align="right"
            color="primary"
            onClick={() => {
              setMode("add");
              setUser({
                correo: "",
                password: "",
                nombre: "",
                celular: "",
                gasera: "",
                rol: "cliente",
                estatus: 0,
              });
              setOpen(true);
            }}
            variant="contained"
          >
            Nuevo
          </Button>
        </Box>
        {/*    <FormDialog
          open={open}
          user={user}
          setOpen={() => {
            setUser({});
            setOpen(false);
          }}
          onChange={(prop, value) => setUser({ ...user, [prop]: value })}
          mode={mode}
        /> */}
        <Alert
          open={Boolean(error)}
          onClose={() => setError("")}
          message={error}
          severity="error"
        />
        <Alert
          open={Boolean(message)}
          onClose={() => setMessage("")}
          message={message}
          severity="success"
        />
        <UsersDialog
          saving={saving}
          open={open}
          user={user}
          onClose={() => setOpen(false)}
          onChange={(key, value) => {
            setUser({ ...user, [key]: value });
          }}
          onErrorPasswords={(_error) => setError(_error)}
          onAccept={mode === "add" ? add : update}
          mode={mode}
        />
        <DeleteDialog
          open={openDelete}
          onCancel={() => setOpenDelete(false)}
          onClose={() => setOpenDelete(false)}
          onAcceptDelete={deleteUser}
        />
        <SyncDialog
          deviceInfo={deviceInfo}
          urls={urls}
          devices={devices}
          user={user}
          openSync={openSync}
          handleSyncRelation={() => setOpenSync(false)}
          deleteUser={() => setOpenSync(false)}
          onClose={() => setOpenSync(false)}
          onAcceptDelete={deleteUser}
        />
        <Box mt={3}>
          <Results
            data={users}
            handleEdit={(row) => {
              setMode("update");
              setOpen(true);
              setUser(row);
            }}
            handleDelete={(row) => {
              setOpenDelete(true);
              setUser(row);
              // setUser(row);
            }}
            handleSync={(row) => {
              setUser(row);
              setOpenSync(true);
              syncDevices(row);
              //syncRelations(row.id);
            }}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
