import React, {useEffect, useState} from "react";
import {useFirestoreConnect,} from "react-redux-firebase";
import {useSelector} from "react-redux";
import {Box, Button, Container, makeStyles, Typography} from "@material-ui/core";
import Page from "../../components/Page";
import Dialog from './Dialog'
import Results from "./Results";
import Services from '../../services';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const title = "Laboratorios";

const Dashboard = () => {

  useFirestoreConnect([
    {
      collection: "laboratorio"
    },
  ]);
  const [openCombo, setComboOpen] = React.useState(false);
  const [models, setModels] = React.useState([]);
  const [model, setModel] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [imageFile, setImageFile] = useState(null)

  const handleComboClose = () => {
    setComboOpen(false);
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  const modelsSelector = useSelector(
    (state) => state.firestore.ordered.laboratorio
  );

  const classes = useStyles();

  useEffect(() => {
    try {
      if (modelsSelector) {
        let _models = [...modelsSelector];

        setModels(_models);
      }
    } catch (e) {
      console.log(e);
    }
  }, [modelsSelector]);

  const add = async () => {
    try {

      let data = {
        nombre: model.nombre === undefined ? "" : model.nombre,
        ciudad: model.ciudad === undefined ? "" : model.ciudad,
        estado: model.estado === undefined ? "" : model.estado,
        fecha: new Date(),
        activo: model.activo,
        token: model.token === undefined ? "" : model.token,
        descripcion: model.descripcion === undefined ? "" : model.descripcion,
      }

      if (imageFile instanceof File) {
        const url = await Services.uploadFile('laboratorio/' + imageFile.name, imageFile);
        data.imagen = url;
        data.imagenPath = 'laboratorio/' + imageFile.name;
      }

      await Services.addDocument('laboratorio', data);

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
      setModel([]);
    }
  };

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Box display="flex" p={1} mt={2}>
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
              variant="contained"
            >
              Nuevo
            </Button>
          </Box>


        </Box>
        <Box mt={3}>
          <Results data={models} title={title}/>
        </Box>
        <Box height="60"></Box>


      </Container>
      <Dialog
        open={open}
        onAccept={add}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {

          if (key === 'imagen') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          } else if (key === 'imagenCrop') {
            setImageFile(value);
          } else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          } else {
            setModel({...model, [key]: value});
          }
        }}
        model={model}
        title={title}
      />
    </Page>
  );
};

export default Dashboard;
