import React, {useEffect, useState} from 'react'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import imageCompression from 'browser-image-compression';

const options = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 600,
  useWebWorker: true
};

function getCanvasBlob(canvas) {
  return new Promise(function (resolve) {
    canvas.toBlob(
      (blob) => {
       resolve(blob)
      },
      'image/png',
      1
    );
  })
}

const Crop = ({url = "", onChange, cropProp = null}) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(null);
  const [dataImg, setDataImg] = useState(null);

  useEffect(() => {
    if (cropProp && !crop) setCrop(cropProp);
  }, [cropProp, crop]);

  const getCroppedImg = async (image, crop) => {
    try {
      image.crossOrigin = "anonymous";
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const blob = await getCanvasBlob(canvas)

      if (!blob) {
        return;
      }

      const file = new File([blob], "file" + Date.now().toString(), {type: "image/png"});
      const compressedBlob = await imageCompression(file, options);
      return new File([compressedBlob], "file" + Date.now().toString(), {type: "image/png"});
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  const onCropComplete = async () => {
    if (image) {
      const croppedImageUrl = await getCroppedImg(image, crop);

      if (croppedImageUrl) onChange(croppedImageUrl);
    }
  }

  return (
    <ReactCrop
      onChange={newCrop => setCrop(newCrop)}
      src={url}
      crop={crop}
      ruleOfThirds
      onComplete={async (e) => {
        setDataImg(e);
        await onCropComplete();
      }}
      onImageLoaded={img => {
        setImage(img);
      }}
    />
  )
}

export default Crop;