import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyCwWD6ew_F6VvHZ7AjzriW2CiDg_0Vros4",
    authDomain: "kroma-6ef02.firebaseapp.com",
    databaseURL: "https://kroma-6ef02.firebaseio.com",
    projectId: "kroma-6ef02",
    storageBucket: "kroma-6ef02.appspot.com",
    messagingSenderId: "988685434701",
    appId: "1:988685434701:web:ddaa121889fd0a17f20104"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
