import React, {useEffect, useState} from 'react'
import {
  Badge,
  Box,
  Button, Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import NewReleases from "@material-ui/icons/NewReleases";
import {CancelRounded, CloudDownload, FitnessCenter} from "@material-ui/icons";
import CheckCircle from "@material-ui/icons/CheckCircle";
import EmailIcon from "@material-ui/icons/Email";
import Services from "../../services";
import JSZip from "jszip";
import {saveAs} from 'file-saver';
import firebase from "firebase/app";
import moment from "moment";
import 'moment/locale/es'
import CancelDialog from "../../components/CancelDialog";
import Table from "../../components/Table";
import MessageDialogView from "../Chat/Dialog";
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import ContactMailIcon from "@material-ui/icons/ContactMail";
import {useSelector} from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  active: {
    backgroundColor: "darkred",
    color: "white"
  },
  activeBeige: {
    backgroundColor: "beige",
    color: "black"
  },
  activeBlanco: {
    backgroundColor: "white",
    color: "#07072d",
    border: "1px solid #07072d",
  },
  activeNegro: {
    backgroundColor: "black",
    color: "white"
  },
  header: {
    fontSize: "16px",
    fontWeight: "bolder"
  },
  title: {
    fontSize: "20px",
    fontWeight: "bolder"
  },
  alinearEstatus: {
    width: "47%",
    display: "inline"
  },
  estrellas: {
    color: "#d59f00",
  }
}));

const HomeDialogView = ({open, title, onCancel, model, saving}) => {
  const auth = useSelector(state => state.firebase.auth);
  const classes = useStyles();
  const [downloading, setDownloading] = useState(false);
  const [imagenes, setImagenes] = useState(0);
  const [imagenesEmpaquetadas, setImagenesEmpaquetadas] = useState(0);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [openDialogMessage, setOpenDialogMessage] = useState(false);
  const [salas, setSalas] = useState([])

  useEffect(() => {
    if (model.id) {
      let _salas = [];
      firebase.firestore().collection("salas").where("idPedido", "==", model.id).onSnapshot(async snapshot => {
        _salas = snapshot.docs.map(d => ({...d.data(), id: d.id}));

        if (_salas.length >= 1) {
          for (let i = 0; i < _salas.length; i++) {
            let _sala = _salas[i];
            let mensajesNoVistos = 0;

            const mensajes = await Services.getCollectionWhere("mensajes", "idSala", _sala.id)
            _salas[i].mensajes = mensajes.docs.map(doc => ({...doc.data(), id: doc.id}));
            _salas[i].mensajesCantidad = mensajes.docs.length;

            for (let j = 0; j < _salas[i].mensajes.length; j++) {
              let _mensajes = _salas[i].mensajes[j];
              if (_mensajes.visto === undefined || _mensajes.visto === 0) {
                if(_mensajes.idUsuario !== auth.uid){
                  mensajesNoVistos++;
                }
              }
            }
            _salas[i].mensajesNoVistos = mensajesNoVistos;
          }
          setSalas(_salas[0])
        } else {
          let data = {
            fecha: new Date(),
            folioPedido: model.folio,
            idCliente: model.idUsuario,
            idOperador: "",
            idPedido: model.id,
            modificado: new Date(),
            nombreCliente: model.usuario.nombre,
            nombreOperador: "",
            terminado: 0,
            tipo: 0,
          }
          Services.addDocument("salas", data);

          firebase.firestore().collection("salas").where('idPedido', "==", model.id)
            .onSnapshot(async snapshot => {
              _salas = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
              for (let i = 0; i < _salas.length; i++) {
                let _sala = _salas[i];
                let mensajesNoVistos = 0;

                const mensajes = await Services.getCollectionWhere("mensajes", "idSala", _sala.id)
                _salas[i].mensajes = mensajes.docs.map(doc => ({...doc.data(), id: doc.id}));
                _salas[i].mensajesCantidad = mensajes.docs.length;

                for (let j = 0; j < _salas[i].mensajes.length; j++) {
                  let _mensajes = _salas[i].mensajes[j];
                  if (_mensajes.visto === undefined || _mensajes.visto === 0) {
                    if(_mensajes.idUsuario !== auth.uid){
                      mensajesNoVistos++;
                    }
                  }
                }
                _salas[i].mensajesNoVistos = mensajesNoVistos;
              }
              setSalas(_salas[0])
            });
        }
      });
    }
  }, [model]);

  const dateRow = (row, type = "") => {
    if (type === "string") {
      row = row.split('T');
      let arrDate = row[0].split('-');
      let arrHora = row[1].split('.');

      return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0] + " " + arrHora[0]
    } else {
      return moment(Date.parse(row.toDate())).locale('es-mx').format('LLLL')
    }
  }

  const changeEstatus = async (estatus, idPedido) => {
    let newStatus = "";
    let fechas = {}

    switch (estatus) {
      case 'NUEVO':
        newStatus = 'ENPROCESO';
        fechas.fechaEnProceso = new Date();
        break;
      case 'PAGADO':
        newStatus = 'ENPROCESO';
        fechas.fechaEnProceso = new Date();
        break;
      case 'ENPROCESO':
        fechas.fechaTerminado = new Date();
        newStatus = 'TERMINADO';
        break;
      case 'TERMINADO':
        fechas.fechaEnviado = new Date();
        newStatus = 'ENVIADO';
        break;
      case 'ENVIADO':
        newStatus = 'ENVIADO';
        break;
      case 'CANCELADO':
        fechas.fechaCancelado = new Date();
        newStatus = 'CANCELADO';
        break;
      case 'REGRESARNUEVO':
        newStatus = 'NUEVO';
        fechas.fechaNuevo = new Date();
        break;
    }
    try {

      let data = {
        ...fechas,
        estatus: newStatus
      }

      await Services.updateDocument('pedido', idPedido, data) //addDocument('pedido', data);
      setOpenDialogCancel(false)

    } catch (error) {
      console.log(error);
    } finally {
      onCancel();
    }
  }

  const gridProductInfo = (model) => {

    if (model < 1)
      return;

    let varEstatus = "";

    if (model.estatus === "NUEVO") {
      varEstatus = "Orden Nueva"
    } else if (model.estatus === "PAGADO") {
      varEstatus = "Orden Pagada, el cliente la esta esperando"
    } else if (model.estatus === "ENPROCESO") {
      varEstatus = "Se esta TRABAJANDO en la Orden"
    } else if (model.estatus === "TERMINADO") {
      varEstatus = "El trabajo esta terminado, listo pare ENVIO al cliente"
    } else if (model.estatus === "ENVIADO") {
      varEstatus = "El trabajo se ha ENVIADO al cliente"
    } else if (model.estatus === "CANCELADO") {
      varEstatus = "El pedido se ha CANCELADO"
    } else {
      let varEstatus = "";
    }

    switch (model.producto.tipo) {
      //en caso que el pedido sea un  kromabook
      case 'PHOTOBOOK' :
        let producto;

        switch (model.producto.color) {
          case 'BLANCO':
            producto = "https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/imagenesSistema%2Fblanco.jpg?alt=media&token=bb185ae0-44ce-4276-acea-58421ce28ba6";
            break;
          case 'blanco':
            producto = "https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/imagenesSistema%2Fblanco.jpg?alt=media&token=bb185ae0-44ce-4276-acea-58421ce28ba6";
            break;
          case 'BEIGE':
            producto = "https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/imagenesSistema%2Fbeige.jpg?alt=media&token=076b524f-10da-4859-9ec6-6751f725b986";
            break;
          case 'beige':
            producto = "https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/imagenesSistema%2Fbeige.jpg?alt=media&token=076b524f-10da-4859-9ec6-6751f725b986";
            break;
          case 'NEGRO':
            producto = "https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/imagenesSistema%2Fnegro.jpg?alt=media&token=bcb06fd8-7f49-4e4c-8ebe-5728a30f201b";
            break;
          case 'negro':
            producto = "https://firebasestorage.googleapis.com/v0/b/kroma-6ef02.appspot.com/o/imagenesSistema%2Fnegro.jpg?alt=media&token=bcb06fd8-7f49-4e4c-8ebe-5728a30f201b";
            break;
        }
        if (model.producto.portada) {
          producto = model.producto.portada;
        }

        return <Grid container spacing={4}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Typography className={classes.header}>Nombre del Álbum</Typography>
            <Typography>{model.producto.nombreAlbum}</Typography>
          </Grid>

          <Grid item lg={6} sm={6} xl={6} xs={12} style={{textAlign: "end"}}>
            {model.evidencias !== undefined && downloading === false ?
              <Box>
                <Chip
                  icon={<CloudDownload style={{color: "white"}}/>}
                  label={"Descargar Álbum con " + model.evidencias.length + " imagenes"}
                  clickable
                  onClick={downloadFiles}
                  style={{color: "white", backgroundColor: "#009cff"}}
                />
              </Box>
              : downloading !== false ?
                <Typography className={classes.header}>Descargando {imagenesEmpaquetadas} imagenes
                  de {imagenes}</Typography>
                : <Typography className={classes.header}>No se encuentran imagenes</Typography>}
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography className={classes.header}>Mensaje Personalizado</Typography>
            <Typography>{model.producto.mensajePersonalizado}</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Cantidad de Fotos</Typography>
            <Typography>{model.producto.cantidadFotos}</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Costo del Pedido</Typography>
            <Typography>{"$ " + (model.costo ? model.costo : 0).toFixed(2)}</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Estado del Pedido</Typography>
            <Typography> {varEstatus}</Typography>
          </Grid>


          <Grid item lg={3} sm={3} xl={3} xs={12}>
            <Typography className={classes.header}>Color de album</Typography>
          </Grid>

          <Grid
            item
            lg={3} sm={3} xl={3} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.color === 'BEIGE' || model.producto.color === 'beige' ? classes.activeBeige : ""}
          >
            <Typography>Beige</Typography>
          </Grid>

          <Grid
            item
            lg={3} sm={3} xl={3} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.color === 'BLANCO' || model.producto.color === 'blanco' ? classes.activeBlanco : ""}>
            <Typography>Blanco</Typography>
          </Grid>

          <Grid item
                lg={3} sm={3} xl={3} xs={12}
                style={{textAlign: "center"}}
                className={model.producto.color === 'NEGRO' || model.producto.color === 'negro' ? classes.activeNegro : ""}>
            <Typography>Negro</Typography>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <img src={producto} style={{maxWidth: "100%"}}/>
          </Grid>

        </Grid>

      //en caso que el pedido sea un  kromabox
      case 'KROMABOX' :

        return <Grid container spacing={3}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Typography className={classes.header}>Nombre del Álbum</Typography>
            <Typography>{model.producto.nombreAlbum}</Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12} style={{textAlign: "end"}}>
            {model.evidencias !== undefined && downloading === false ?
              <Box>
                <Chip
                  icon={<CloudDownload style={{color: "white"}}/>}
                  label={"Descargar Álbum con " + model.evidencias.length + " imagenes"}
                  clickable
                  onClick={downloadFiles}
                  style={{color: "white", backgroundColor: "#009cff"}}
                />
              </Box>
              : downloading !== false ?
                <Typography className={classes.header}>Descargando {imagenesEmpaquetadas} imagenes
                  de {imagenes}</Typography>
                : <Typography className={classes.header}>No se encuentran imagenes</Typography>}
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography className={classes.header}>Mensaje Personalizado</Typography>
            <Typography>{model.producto.mensajePersonalizado}</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Cantidad de Fotos</Typography>
            <Typography>{model.producto.cantidadFotos}</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Costo del Pedido</Typography>
            <Typography>{"$ " + (model.costo ? model.costo : 0).toFixed(2)}</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Estado del Pedido</Typography>
            <Typography>{varEstatus}</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Tamaño</Typography>
          </Grid>

          <Grid
            item
            lg={4} sm={4} xl={4} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.tamano === '5X5' || model.producto.tamano === '5x5' ? classes.active : ""}
          >
            <Typography>5x5</Typography>
          </Grid>

          <Grid item
                lg={4} sm={4} xl={4} xs={12}
                style={{textAlign: "center"}}
                className={model.producto.tamano === '4X6' || model.producto.tamano === '4x6' ? classes.active : ""}
          >
            <Typography>4x6</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Tipo de Papel</Typography>
          </Grid>

          <Grid
            item
            lg={4} sm={4} xl={4} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.color === 'brillante' ? classes.active : ""}
          >
            <Typography>Brillante</Typography>
          </Grid>

          <Grid
            item
            lg={4} sm={4} xl={4} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.color === 'mate' ? classes.active : ""}>
            <Typography>Mate</Typography>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12} style={{textAlign: "center"}}>
            <Typography className={classes.header}>Diseño de Etiqueta</Typography>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <img src={model.producto.etiqueta} style={{maxWidth: "100%"}}/>
          </Grid>

        </Grid>

      //en caso que el pedido sea un  kromaluxe
      case 'KROMALUXE' :

        return <Grid container spacing={9}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <Typography className={classes.header}>Nombre del Álbum</Typography>
            <Typography>{model.producto.nombreAlbum}</Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12} style={{textAlign: "end"}}>
            {model.evidencias !== undefined && model.evidencias.length > 0 && downloading === false ?

              <Box>
                <Chip
                  icon={<CloudDownload style={{color: "white"}}/>}
                  label={"Descargar Álbum con " + model.evidencias.length + " imagenes"}
                  clickable
                  onClick={downloadFiles}
                  style={{color: "white", backgroundColor: "#009cff"}}
                />
              </Box> : downloading !== false ?
                <Typography className={classes.header}>Descargando {imagenesEmpaquetadas} imagenes
                  de {imagenes}</Typography>
                : <Typography className={classes.header}>No se encuentran imagenes</Typography>}
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography className={classes.header}>Mensaje Personalizado</Typography>
            <Typography>{model.producto.mensajePersonalizado}</Typography>
          </Grid>

          <Grid item lg={3} sm={3} xl={3} xs={12}>
            <Typography className={classes.header}>Cantidad de KromaLuxe</Typography>
            <Typography>{model.producto.cantidadKromaluxe}</Typography>
          </Grid>

          <Grid item lg={3} sm={3} xl={3} xs={12}>
            <Typography className={classes.header}>Cantidad de Fotos</Typography>
            <Typography>{model.producto.cantidadFotos}</Typography>
          </Grid>

          <Grid item lg={3} sm={3} xl={3} xs={12}>
            <Typography className={classes.header}>Costo del Pedido</Typography>
            <Typography>{"$ " + (model.costo ? model.costo : 0).toFixed(2)}</Typography>
          </Grid>

          <Grid item lg={3} sm={3} xl={3} xs={12}>
            <Typography className={classes.header}>Estado del Pedido</Typography>
            <Typography>{varEstatus}</Typography>
          </Grid>

          <Grid item lg={3} sm={3} xl={3} xs={12}>
            <Typography className={classes.header}>Tamaño</Typography>
          </Grid>

          <Grid
            item
            lg={3} sm={3} xl={3} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.tamano === '4x4' ? classes.active : ""}
          >
            <Typography>4x4</Typography>
          </Grid>

          <Grid item
                lg={3} sm={3} xl={3} xs={12}
                style={{textAlign: "center"}}
                className={model.producto.tamano === '8x10' ? classes.active : ""}
          >
            <Typography>8x10</Typography>
          </Grid>

          <Grid item
                lg={3} sm={3} xl={3} xs={12}
                style={{textAlign: "center"}}
                className={model.producto.tamano === '8x8' ? classes.active : ""}
          >
            <Typography>8x8</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Tipo de Kromaluxe</Typography>
          </Grid>

          <Grid
            item
            lg={4} sm={4} xl={4} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.tipoKromaluxe === "mosaico" ? classes.active : ""}
          >
            <Typography>Mosaico</Typography>
          </Grid>

          <Grid
            item
            lg={4} sm={4} xl={4} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.tipoKromaluxe === "normal" ? classes.active : ""}>
            <Typography>Normal</Typography>
          </Grid>

          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <Typography className={classes.header}>Tipo de Soporte</Typography>
          </Grid>

          <Grid
            item
            lg={4} sm={4} xl={4} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.tipoSoporte === 'pared' ? classes.active : ""}
          >
            <Typography>Pared</Typography>
          </Grid>

          <Grid
            item
            lg={4} sm={4} xl={4} xs={12}
            style={{textAlign: "center"}}
            className={model.producto.tipoSoporte === 'escritorio' ? classes.active : ""}>
            <Typography>Escritorio</Typography>
          </Grid>
        </Grid>
    }
  }

  const gridDatosCliente = (model) => {
    if (model < 1)
      return;

    return <Grid container spacing={3}>
      {
        () => {
          switch (model.estatus) {
            case "NUEVO":
              return <>
                <Grid item xs={8}>
                  <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                                     name="gilad"/>}
                    label="en proceso"
                  />
                </Grid>
              </>
            case "PAGADO":
              return <>
                <Grid item xs={8}>
                  <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                                     name="gilad"/>}
                    label="en proceso"
                  />
                </Grid>
              </>
            case "ENPROCESO":
              return <>
                <Grid item xs={8}>
                  <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                                     name="gilad"/>}
                    label="Terminar"
                  />
                </Grid>
              </>
            case "TERMINADO":
              return <>
                <Grid item xs={8}>
                  <Typography className={classes.title}>{title}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={<Switch checked={model.activo} onChange={event => onChange('activo', event.target.value)}
                                     name="gilad"/>}
                    label="Enviar"
                  />
                </Grid>
              </>
            default:
              return <Typography className={classes.title}/>
          }
        }
      }

      <Grid item xs={4}>
        <Typography className={classes.header}>Fecha de la orden</Typography>
        <Typography>{model.fechaCreado ? dateRow(model.fechaCreado) : ""}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography className={classes.header}>Numero de la orden</Typography>
        <Typography>{model.folio}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography className={classes.header}>Paquete seleccionado</Typography>
        <Typography>{model.producto.tipo} </Typography>
      </Grid>

      <Grid item xs={12}>
        <hr/>
      </Grid>

      <Grid item xs={6}>

        <Typography className={classes.header}>Recibe</Typography>
        <Typography>{model.recibe}</Typography>
        <Typography className={classes.header}>Entrega de 5-7 dias habiles</Typography>
        <Typography>Total <strong>$ {model.costo.toFixed(2)}</strong></Typography>
      </Grid>

      {
        model.pagado ? <Grid item xs={6}>
          <Typography className={classes.header}>Pago</Typography>
          <Typography className={classes.header}>Fecha pago</Typography>
          <Typography>{dateRow(model.pago.aprovacion, 'string')}</Typography>
          <Typography className={classes.header}>Tipo de pago</Typography>
          <Typography>{model.pago.tipo}</Typography>
          <Typography className={classes.header}>Monto</Typography>
          <Typography>${model.pago.monto.toFixed(2)}</Typography>
        </Grid> : ""

      }

      <Grid item xs={12}>
        <hr/>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.header}>Nombre</Typography>
        <Typography>{model.usuario.nombre}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.header}>Correo Electroníco</Typography>
        <Typography>{model.usuario.correo}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.header}>Dirección</Typography>
        <Typography>{
          /*model.direccion.direccion*/
          model.direccion.calle + " " + model.direccion.numero + " " + model.direccion.ciudad
          + " " + model.direccion.numero + ", " + model.direccion.colonia + ", "
          + model.direccion.ciudad + ", " + model.direccion.estado
        }</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.header}>Código Postal</Typography>
        <Typography>{model.direccion.codigopostal} </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography className={classes.header}>Teléfono</Typography>
        <Typography>{model.usuario.telefono}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.header}>Referencias</Typography>
        <Typography>{model.direccion.referencia}</Typography>
      </Grid>

    </Grid>
  }

  const downloadFiles = async () => {
    let content = null;
    try {
      let zip = new JSZip();

      setDownloading(true);
      setImagenes(model.evidencias.length)

      for (let i = 0; i < model.evidencias.length; i++) {
        let item = model.evidencias[i];
        let response = await fetch(item.urlFirestore)
        let responseBlob = await response.blob();
        zip.file(item.urlOriginal, responseBlob, {binary: true});
        setImagenesEmpaquetadas(i + 1);
      }

      if (model.producto.portada) {
        let item = model.producto.portada;
        let response = await fetch(item)
        let responseBlob = await response.blob();
        zip.file('000-Portada_KromaBook.jpg', responseBlob, {binary: true});
      }

      content = await zip.generateAsync({type: "blob"})

    } catch (error) {
      console.log(error)
    } finally {
      if (content) {
        saveAs(content, model.folio + ".zip")
      }
      setDownloading(false);
    }
  }

  const estatusRow = (stat, id, cancelar) => {
    if (cancelar) {
      return <>
        <Box className={classes.alinearEstatus}>
          <Chip
            icon={<CancelRounded style={{color: "white"}}/>}
            label="Cancelar"
            clickable
            onClick={() => {
              setOpenDialogCancel(true);
            }}
            style={{backgroundColor: "red", color: "white"}}
          />
        </Box>
      </>
    } else {
      switch (stat) {
        case 'NUEVO':
          return <>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<NewReleases style={{color: "white"}}/>}
                label="Nuevo"
                clickable
                style={{color: "white", backgroundColor: "blue"}}
              />
            </Box>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<FitnessCenter style={{color: "white"}}/>}
                label="Trabajar Pedido"
                clickable
                onClick={() => {
                  changeEstatus(stat, id)
                }}
                style={{color: "white", backgroundColor: "orange"}}
              />
            </Box>
          </>

        case 'PAGADO':
          return <>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<NewReleases style={{color: "blue"}}/>}
                label="Nuevo"
                clickable
                style={{color: "blue"}}
              />
            </Box>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<FitnessCenter style={{color: "white"}}/>}
                label="Trabajar Pedido"
                clickable
                onClick={() => {
                  changeEstatus(stat, id)
                }}
                style={{color: "white", backgroundColor: "orange"}}
              />
            </Box>
          </>
        case 'ENPROCESO':
          return <>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<NewReleases style={{color: "white"}}/>}
                label="Regresar a Nuevo"
                clickable
                onClick={() => {
                  changeEstatus('REGRESARNUEVO', id)
                }}
                style={{color: "white", backgroundColor: "blue"}}
              />
            </Box>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<FitnessCenter style={{color: "white"}}/>}
                label="En Proceso"
                clickable
                style={{color: "white", backgroundColor: "orange"}}
              />
            </Box>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<CheckCircle style={{color: "white"}}/>}
                label="Terminar Pedido"
                clickable
                onClick={() => {
                  changeEstatus(stat, id)
                }}
                style={{color: "white", backgroundColor: "green"}}
              />
            </Box>
          </>
        case 'TERMINADO':
          return <>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<FitnessCenter style={{color: "white"}}/>}
                label="Regresar a Trabajar"
                clickable
                onClick={() => {
                  changeEstatus('NUEVO', id)
                }}
                style={{color: "white", backgroundColor: "orange"}}
              />
            </Box>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<CheckCircle style={{color: "white"}}/>}
                label="Terminado"
                clickable
                style={{color: "white", backgroundColor: "green"}}
              />
            </Box>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<EmailIcon style={{color: "white"}}/>}
                label="Enviar Pedido"
                clickable
                onClick={() => {
                  changeEstatus(stat, id)
                }}
                style={{color: "white", backgroundColor: "green"}}
              />
            </Box>
          </>
        case 'ENVIADO':
          return <Box>
            <Box className={classes.alinearEstatus}>
              <Chip
                icon={<CheckCircle style={{color: "white"}}/>}
                label="Marcar terminado"
                clickable
                onClick={() => {
                  changeEstatus('ENPROCESO', id)
                }}
                style={{color: "white", backgroundColor: "green"}}
              />
            </Box>
            <Chip
              icon={<EmailIcon style={{color: "white"}}/>}
              label="Enviado"
              clickable
              style={{color: "white", backgroundColor: "green"}}
            />
          </Box>
        case 'CANCELADO':
          return <Box>
            <Chip
              icon={<CancelRounded style={{color: "white"}}/>}
              label="Cancelado"
              clickable
              style={{color: "white", backgroundColor: "red"}}
            />
          </Box>
      }
    }
    return stat
  }

  const stars = (star) => {
    if (star === 1)
      return <>
        <StarIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
      </>
    else if (star === 2)
      return <>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
      </>
    else if (star === 3)
      return <>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
      </>
    else if (star === 4)
      return <>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
      </>
    else if (star === 5)
      return <>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
        <StarIcon className={classes.estrellas}/>
      </>
    else
      return <>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
        <StarOutlineIcon className={classes.estrellas}/>
      </>
  }

  return (
    <>
      <CancelDialog
        open={openDialogCancel}
        onAcceptCancel={() => changeEstatus('CANCELADO', model.id)}
        info={"el pedido con folio " + model ? model.folio : ""}
        onCancel={() => {
          setOpenDialogCancel(false);
          open = false;
        }}
      />
      <Dialog open={open}>
        <DialogContent>
          <DialogTitle style={{
            backgroundColor: "#1c1c1c",
            color: "white",
            marginBottom: "3%",
            textAlign: "center"
          }}>
            <Grid container>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                {model < 1 ? "" : model.producto.tipo}
              </Grid>
              <Grid item lg={6} sm={6} xl={6} xs={6}>
                {estatusRow(model.estatus, model.id, false)}
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                {estatusRow(model.estatus, model.id, true)}
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid container>
            {/**
             div de imnformacion de producto
             */}
            <Grid item xs={6}>
              {gridProductInfo(model)}
              {model.comentariosCalificacion ? <><Typography
                className={classes.header}> Comentario: </Typography><Typography> {model.comentariosCalificacion}  </Typography></> : ""}
              {model.calificacion ? <><Typography
                className={classes.header}> Calificacion: </Typography><Typography> {stars(model.calificacion)}  </Typography></> : ""}
            </Grid>

            {/**
             div de imnformacion de contacto
             */}
            <Grid item xs={6}>
              {gridDatosCliente(model)}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size='large'
                    color="primary"
                    style={{width: '100%'}}
                    onClick={(model) => {
                      setOpenDialogMessage(true);
                    }}
                  >
                    Enviar Mensaje
                    {salas.mensajesNoVistos >= 1 ?
                      <IconButton
                        style={{backgroundColor: "#f80000", color: "white", margin: '0 27px'}}
                      >
                        <Badge badgeContent={salas.mensajesNoVistos}>
                          <EmailIcon color="action" style={{color:'#ffffff'}} />
                        </Badge>
                      </IconButton> : ""
                    }
                  </Button>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} style={{color: 'white', backgroundColor: '#232e5c'}}>
            Ok
          </Button>
        </DialogActions>

        <MessageDialogView
          open={openDialogMessage}
          model={salas}
          mensajes={salas.mensajes}
          onCancel={() => {
            setOpenDialogMessage(false);
          }}
        />
      </Dialog>

    </>

  )
}

export default HomeDialogView
