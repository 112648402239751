import React from 'react';
import { Typography, TextField } from '@material-ui/core';

export default function ShowTextInput({title, answer, multiline }) {
  return(
    <>
      <Typography><b>{title}</b></Typography>
      <TextField 
        variant="outlined" 
        onChange={(event) => answer(event.target.value)}
        multiline
        rows={multiline ? 4 : 0}
        fullWidth
      />
    </>
  )
}