import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors,
} from "@material-ui/core";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import { Checkbox, IconButton } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DriveEta from "@material-ui/icons/DriveEta";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56,
  },
}));

const TasksProgress = ({
  className,
  handleRuta,
  handleTerminado,
  handleBack,
  info,
  ...rest
}) => {
  const classes = useStyles();
  const obj = {
    NUEVO: 25,
    ASIGNADO: 50,
    "EN RUTA": 75,
    TERMINADO: 100,
    CANCELADO: 0,
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              AVANCE DE PEDIDO
            </Typography>
            <Box display="flex" flexDirection="row" p={1} m={1}>
              <Box p={1}>
                <Typography color="textPrimary" variant="h5">
                  {info.estatus == "NUEVO" ? "Nuevo" : null}
                  {info.estatus == "ASIGNADO" ? "Chofer Asignado" : null}
                  {info.estatus == "EN RUTA" ? "Chofer en Ruta" : null}
                  {info.estatus == "TERMINADO" ? "Terminado" : null}
                  {info.estatus == "CANCELADO" ? "Cancelado" : null}
                </Typography>
              </Box>

              <Box p={1}>
                {info.estatus == "NUEVO" ? (
                  <IconButton
                    style={{ backgroundColor: "#2a3d6b", color: "white" }}
                  >
                    <NewReleases />
                  </IconButton>
                ) : null}
                {info.estatus == "ASIGNADO" ? (
                  <IconButton
                    style={{
                      backgroundColor: "#213acc",
                      color: "white",
                    }}
                    onClick={() => handleBack()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                ) : null}
                {info.estatus == "ASIGNADO" ? (
                  <IconButton
                    style={{
                      backgroundColor: "#213acc",
                      color: "white",
                    }}
                    onClick={() => handleRuta()}
                  >
                    <CompareArrowsIcon />
                  </IconButton>
                ) : null}
                {info.estatus == "EN RUTA" ? (
                  <IconButton
                    style={{ backgroundColor: "#c9500e", color: "white" }}
                    onClick={() => handleBack()}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                ) : null}
                {info.estatus == "EN RUTA" ? (
                  <IconButton
                    style={{ backgroundColor: "#c9500e", color: "white" }}
                    onClick={() => handleTerminado()}
                  >
                    <DriveEta />
                  </IconButton>
                ) : null}
                {info.estatus == "TERMINADO" ? (
                  <IconButton
                    style={{ backgroundColor: "#3ecc3b", color: "white" }}
                    onClick={() => handleEdit()}
                  >
                    <CheckCircle />
                  </IconButton>
                ) : null}{" "}
                {info.estatus == "CANCELADO" ? (
                  <IconButton
                    style={{ backgroundColor: "#c93030", color: "white" }}
                    onClick={() => handleEdit()}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </Box>
            </Box>
          </Grid>
          <Grid item>
            {/*  <Avatar className={classes.avatar}>
              <InsertChartIcon />
            </Avatar> */}
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress value={obj[info.estatus]} variant="determinate" />
        </Box>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string,
};

export default TasksProgress;
