import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Table from "../../components/Table";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Typography,
  makeStyles,
  IconButton,
  Container,
  Chip,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DriveEta from "@material-ui/icons/DriveEta";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import SyncAlt from "@material-ui/icons/SyncAlt";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },
  {
    prop: "celular",
    name: "Telefono",
  },
  {
    prop: "email",
    name: "Correo Electrónico",
  },
  {
    prop: "gasera",
    name: "Gasera",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  handleSync,
  handledriver,
  handleDelete,
  data,
  data2,
  handleRemoveDriver,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        extraRows={[
          {
            prop: "none",
            name: "Estatus",
            cell: (row) => (
              <Box>
                {row.estatus == "0" ? (
                  <Chip
                    icon={<AssignmentIndIcon style={{ color: "white" }} />}
                    size="small"
                    label="Chofer Desocupado"
                    clickable
                    style={{ color: "white", backgroundColor: "#70d134" }}
                  />
                ) : null}
                {row.estatus == "1" ? (
                  <Chip
                    icon={<EventBusyIcon style={{ color: "white" }} />}
                    size="small"
                    label="Chofer Ocupado"
                    clickable
                    style={{ color: "white", backgroundColor: "#ff3030" }}
                  />
                ) : null}
              </Box>
            ),
          },

          {
            prop: "none2",
            name: "Asignar",
            cell: (row) => (
              <Box>
                {data2.estatus == "NUEVO" ? (
                  <IconButton
                    style={{ backgroundColor: "#03a5fc", color: "white" }}
                    onClick={() => handledriver(row)}
                  >
                    <SyncAlt />
                  </IconButton>
                ) : null}
              </Box>
            ),
          },

          {
            prop: "none3",
            name: "Cancelar",
            cell: (row) => (
              <Box>
                {data2.estatus != "NUEVO" &&
                (data2.estatus != "CANCELADO") &
                  (data2.estatus != "TERMINADO") ? (
                  <IconButton
                    style={{ backgroundColor: "#de091e", color: "white" }}
                    onClick={() => handleRemoveDriver(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </Box>
            ),
          },
        ]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
