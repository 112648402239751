import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import {Box, Card, Chip, IconButton, makeStyles,} from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import {Link} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './Dialog'
import Services from '../../services';
import DeleteDialog from '../../components/DeleteDialog';

const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },
  {
    prop: "descripcion",
    name: "Descripción",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({className, handleSync, handleEdit, handleDelete, title, data, ...rest}) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);

  const update = async () => {
    try {

      let data = {
        nombre: model.nombre,
        descripcion: model.descripcion,
        precio: model.precio,
        fecha: model.fecha.seconds !== undefined ? model.fecha : new Date(model.fecha),
        activo: model.activo,
      };

      if (model.imagen && typeof model.imagen !== "string" ) {
        const url = await Services.uploadFile('anuncios/' + model.imagen.name, model.imagen);
        data.imagen = url;
        data.imagenPath = 'anuncios/' + model.imagen.name;
      }

      await Services.updateDocument('anuncios', model.id, data)

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const borrar = async () => {
    try {
      await Services.delete('anuncios', model.id);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogDelete(false);
      setModel(false);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "descriocion", "uid"]} extraRows={[
        {
          prop: "precioPorLitro",
          name: "Precio por Litro",
          cell: (row) => <>$ {Number.parseFloat(row.precio).toFixed(2)} </>,
        }, {
          prop: 'image',
          name: 'Imagen',
          cell: row => (
            <img height={50} src={row.imagen}/>
          )
        },
        {
          prop: "estatus",
          name: "Estatus",
          cell: (row) => (
            <Link
              to={{
                pathname: `/pedido`,
                state: row,
              }}
            >
              <Box>
                {row.activo == true ? (
                  <Chip
                    icon={<NewReleases style={{color: "white"}}/>}
                    size="small"
                    label="Activo"
                    clickable
                    style={{color: "white", backgroundColor: "green"}}
                  />
                ) : <Chip
                  icon={<NewReleases style={{color: "white"}}/>}
                  size="small"
                  label="Inactivo"
                  clickable
                  style={{color: "white", backgroundColor: "red"}}
                />}

              </Box>
            </Link>
          ),
        },
        {
          prop: 'actions',
          name: '',
          cell: row => (
            <>
            <IconButton
              style={{backgroundColor: "#f0ed18", color: "white"}}
              onClick={() => {
                setOpen(true);
                setModel(row);
              }}>
              <EditIcon/>
            </IconButton>
            <IconButton
              style={{backgroundColor: "#f02d1d", color: "white"}}
              onClick={() => {
                setOpenDialogDelete(true);
                setModel(row);
              }}>
              <DeleteIcon/>
            </IconButton>
            </>
          )
        }
      ]}
      />
      <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === 'image') {
            setModel({...model, [key]: value, imagen: URL.createObjectURL(value)});
          }
          else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          }
          else {
            setModel({...model, [key]: value});
          }

        }}
        model={model}
        title={title}
      />
      <DeleteDialog
        open={openDialogDelete}
        onAcceptDelete={borrar}
        onCancel={() => {
          setOpenDialogDelete(false);
          setModel({});
        }}
      />
    </Card>


  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
