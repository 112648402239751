import React, {useEffect, useState} from "react";
import clsx from "clsx";
import Table from "../../components/Table";
import {Box, Card, Chip, IconButton, makeStyles,} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import NewReleases from "@material-ui/icons/NewReleases";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {CancelRounded, FitnessCenter, MailOutline, MailOutlined, Visibility} from "@material-ui/icons";
import EmailIcon from "@material-ui/icons/Email";
import Dialog from "../Dashboard/Dialog";
import {useFirestoreConnect} from "react-redux-firebase";
import Services from "../../services";
import moment from "moment";
import 'moment/locale/es-mx'
import CancelDialog from "../../components/CancelDialog";
import app from "../../firebase";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DraftsIcon from '@material-ui/icons/Drafts';
import MessageDialogView from "../../../src/views/Chat/Dialog"

const header = [
  {
    prop: "folio",
    name: "Folio",
  }, {
    prop: "envia",
    name: "Envía",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const chips = {
  NUEVO: (
    <Chip
      icon={<NewReleases style={{color: "white"}}/>}
      size="small"
      label="Nuevo"
      clickable
      style={{color: "white", backgroundColor: "black"}}
    />
  ),
};

const Results = ({
                   className,
                   handleSync,
                   handleEdit,
                   handleDelete,
                   data,
                   onClick
                 }) => {
  useFirestoreConnect([
    {
      collection: "pedido"
    },
  ]);

  const classes = useStyles();
  const [model, setModel] = useState([]);
  const [title, setTitle] = useState();
  const [open, setOpen] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [openDialogMessage, setOpenDialogMessage] = useState(false);
  const [catchInicio, setCatchInicio] = useState("");
  const [catchFin, setCatchFin] = useState("");
  const [mensajes, setMensajes] = useState([]);
  const db = app.firestore();

  useEffect(() => {

  }, [catchInicio, catchFin])

  const changeEstatus = async (estatus, idPedido, cancel) => {
    let newStatus = "";
    let fechas = {}

    if (cancel === false) {
      switch (estatus) {
        case 'NUEVO':
          newStatus = 'ENPROCESO';
          fechas.fechaEnProceso = new Date();
          break;
        case 'PAGADO':
          newStatus = 'ENPROCESO';
          fechas.fechaEnProceso = new Date();
          break;
        case 'ENPROCESO':
          fechas.fechaTerminado = new Date();
          newStatus = 'TERMINADO';
          break;
        case 'TERMINADO':
          fechas.fechaEnviado = new Date();
          newStatus = 'ENVIADO';
          break;
        case 'ENVIADO':
          newStatus = 'ENVIADO';
          break;
        case 'CANCELADO':
      }
    } else {
      fechas.fechaCancelado = new Date();
      newStatus = 'CANCELADO';
    }

    try {

      let data = {
        ...fechas,
        estatus: newStatus,
      }


      await Services.updateDocument('pedido', idPedido, data) //addDocument('pedido', data);

    } catch (error) {
      console.log(error);
    } finally {
      console.log(newStatus, idPedido);
    }
  }

  const stats = (estatus, idPedido) => {

    switch (estatus) {
      case 'NUEVO':
        setTitle("Orden Nueva")
        return <Box>
          <Chip
            icon={<NewReleases style={{color: "blue"}}/>}
            size="small"
            label="Nuevo"
            clickable
            onClick={() => {
              changeEstatus(estatus, idPedido, false)
            }}
            style={{color: "blue"}}
          />
        </Box>
      case 'PAGADO':
        setTitle("Orden Pagada")
        return <Box>
          <Chip
            icon={<AttachMoneyIcon style={{color: "white"}}/>}
            size="small"
            label="Pagado"
            clickable
            onClick={() => {
              changeEstatus(estatus, idPedido, false)
            }}
            style={{color: "white", backgroundColor: "blue"}}
          />
        </Box>
      case 'ENPROCESO':
        return <Box>
          <Chip
            icon={<FitnessCenter style={{color: "white"}}/>}
            size="small"
            label="En Proceso"
            clickable
            onClick={() => {
              changeEstatus(estatus, idPedido, false)
            }}
            style={{color: "white", backgroundColor: "orange"}}
          />
        </Box>
      case 'TERMINADO':
        setTitle("Orden Terminada")
        return <Box>
          <Chip
            icon={<CheckCircle style={{color: "white"}}/>}
            size="small"
            label="Terminado"
            clickable
            onClick={() => {
              changeEstatus(estatus, idPedido, false)
            }}
            style={{color: "white", backgroundColor: "green"}}
          />
        </Box>
      case 'ENVIADO':
        setTitle("Orden Terminada y Enviada")
        return <Box>
          <Chip
            icon={<EmailIcon style={{color: "white"}}/>}
            size="small"
            label="Enviado"
            clickable
            style={{color: "white", backgroundColor: "green"}}
          />
        </Box>
      case 'CANCELADO':
        setTitle("Orden Cancelada")
        return <Box>
          <Chip
            icon={<CancelRounded style={{color: "white"}}/>}
            size="small"
            label="Cancelado"
            clickable
            style={{color: "white", backgroundColor: "red"}}
          />
        </Box>
    }

  }

  const dateRow = (row, type = "") => {
    if (type === "string") {
      row = row.split('T');
      let arrDate = row[0].split('-');
      let arrHora = row[1].split('.');

      return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0] + " " + arrHora[0]
    }else{
      return moment(Date.parse(row.toDate())).locale('es-mx').format('LLLL')
    }
  }

  const cancelar = () => {
    try {
      changeEstatus(model.estatus, model.id, true)
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogCancel(false);
      setModel([]);
    }
  }

  const mensajesRow = (mensajes, model) => {

    let icon = "";
    let style = "";
    let label = "";

    try{
      if(mensajes.length > 0){
        mensajes.map((mens)=>{
          if(mens.leido === null){
            icon = <ContactMailIcon style={{color: "white"}}/>
            style = {color: "white", backgroundColor: "red"}
          }
          else {
            icon = <DraftsIcon style={{color: "white"}}/>
            style = {color: "white", backgroundColor: "green"}
          }
        })

        return <IconButton
          style={style}
          onClick={() => {
            setMensajes(mensajes);
            setModel(model)
            setOpenDialogMessage(true);
          }}>
          {icon}
        </IconButton>
      }
      else{
        return ""
      }
    }catch (error){

    }
  }


  return (
    <Card className={clsx(classes.root, className)}>
      <Table
        onClick={onClick}
        header={header}
        data={data.sort((a, b) => a.fechaCreado < b.fechaCreado ? 1 : -1)}
        paginated
        //initDate={inicio}
        //endDate={fin}
        filter={[
          "uid",
          "folio",
          "envia",
          "tipoProducto",
          "nombreAlbum",
          "fechaPago",
          "fechaRegistro"]}
        extraRows={[
          {
            prop: "tipoProducto",
            name: "Producto",
            cell: (row) => (
              row.producto.tipo
            ),
          }, {
            prop: "nombreAlbum",
            name: "Nombre del Álbum",
            cell: (row) => (
              row.producto.nombreAlbum
            ),
          }, {
            prop: "tamano",
            name: "Tamaño",
            cell: (row) => (
              row.producto.tamano
            ),
          }, {
            prop: "costo",
            name: "Costo",
            cell: (row) => (
              "$ " + (row.costo ? row.costo : 0).toFixed(2)
            ),
          }, {
            prop: "fechaRegistro",
            name: "Fecha de Registro",
            cell: (row) => (
              dateRow(row.fechaCreado)
            ),
          }, {
            prop: "fechaPago",
            name: "Fecha de Pago",
            cell: (row) => (
              row.pagado ? dateRow(row.pago.aprovacion, "string") : "no se ha pagado"
            ),
          },
          {
            prop: "estatus",
            name: "Estatus",
            cell: (row) => (
              stats(row.estatus, row.id)
            ),
          },
          {
            prop: "mensajes",
            name: "",
            cell: (row) => (
              mensajesRow(row.mensajes, row)
            ),
          },
          {
            prop: "actions",
            name: "",
            cell: (row) => (
              <>
                <IconButton
                  style={{backgroundColor: "#00b3f8", color: "white"}}
                  onClick={() => {
                    setOpen(true);
                    setModel(row);
                  }}>
                  <Visibility/>
                </IconButton>
                {row.estatus === "CANCELADO" || row.estatus === "ENVIADO" ? "" : <IconButton
                  style={{backgroundColor: "red", color: "white"}}
                  onClick={() => {
                    setOpenDialogCancel(true);
                    setModel(row);
                    //changeEstatus(row.estatus, row.id, true);
                  }}>
                  <CancelRounded/>
                </IconButton>
                }

              </>
            ),
          },

        ]}
      />

      <Dialog
        open={open}
        onCancel={() => {
          setOpen(false);
          setModel([]);
        }}
        model={model}
        title={title}
      />

      <CancelDialog
        open={openDialogCancel}
        onAcceptCancel={cancelar}
        info={"el pedido con folio " + model.folio}
        onCancel={() => {
          setOpenDialogCancel(false);
          setModel([]);
        }}
      />

      <MessageDialogView
        open={openDialogMessage}
        mensajes={mensajes}
        model={model}
        tipo="pedido"
        onCancel={() => {
          setOpenDialogMessage(false);
          setMensajes([]);
        }}
      />
    </Card>
  );
};

export default Results;
